import { UserService } from './../user.service';
import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { pipe } from 'rxjs';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})


export class HomeComponent implements OnInit {
  showSelected: boolean;
  isShown:boolean|any
  oppoSuitsForm:any
  currentUser: string[] | any = []
  categoryList: string[] | any = []
  subscription: string[] | any = []
  products: string[] | any = []

  topicSubscription: string[] | any = []
  topic: string[] | any = []

  websiteList:string[]|any=[]
  websiteLists:string[] | any = []

  videoList:string[]|any=[]
  videoLists:string[]| any = []

  hashTag: string[] | any = []
  hashtagList:string[]|any=[]

  platform:string[]|any=[]
  platformDetails:string[]|any=[]
  submitted = false;
  url:any
  search:any
  variable = false;
  dealsList:any
  deals:any
  user_id:any
  platformIds:any
  platformArray:any
  val:any
  i:any
  arrays:[]|any
  twitter:any
  function:any
  twt:any
  insta:any
  fab:any
  lkn:any
  ytb:any
  msg:any
  yub: string|any;
  fab1: number|any;
  twt1: number|any;
  insta1: number|any;
  islink: false|any;





  constructor(
    private route: Router,
    private auth: AuthenticationService,
    private userService: UserService,
    public sanitizer: DomSanitizer,
    private fb:FormBuilder
  ) {
    this.showSelected = false;
   
    /**
     * Category List
     */
    this.subscription = this.userService.CategoryList().subscribe(products => {
      //  console.log("pppp",products);
      this.products = products;
    });
    /**
     * Trending Topic List
     */
    this.topicSubscription = this.userService
      .TrendingTopicList()
      .subscribe(topic => {
       // console.log('topic',topic);
        this.topic = topic;
      });

    /**
     * Websites  List
     */
    this.websiteList = this.userService.TrendingWebsitesList()
    .subscribe(websiteLists => {
      this.websiteLists = websiteLists;
    });

      /**
     * Youtube Video List
     */
       this.videoList = this.userService.youtubeList()
       .subscribe(videoLists => {
        // console.log(videoLists);
         this.videoLists = videoLists;
        
       });

         /**
     * HashTag  List
     */
     this.hashTag = this.userService
     .HashTagList()
     .subscribe(hashtagList => {
       this.hashtagList = hashtagList;
     });

        /**
     * Platform  List
     */
         this.platform = this.userService
         .platformList()
         .subscribe(platformDetails => {
           this.platformDetails = platformDetails;
         });

            /**
     * Deals and Offer  List
     */
             this.dealsList = this.userService
             .DealsAndOffer()
             .subscribe(deals => {
               //console.log("deal",deals);
               this.deals = deals;
             });

  }

  ngOnInit(): void {
   
    this.user_id = window.sessionStorage.getItem('id');
  
    this.oppoSuitsForm = this.fb.group({
      search: ['', [Validators.required]],
      link: ['', [Validators.required]]
    })
    this.currentUser = this.auth.getAllData();
   // console.log(this.currentUser);
     this.user_id;


  }

  logout() {
    this.auth.destroyToken();
    this.route.navigate(['/login']);
  }

  changeSuit(e:any) {
    this.oppoSuitsForm.get('link').setValue(e.target.value, {
      onlySelf: true
    })
  }

  onSubmit() {
    this.submitted = true;
    this.url = this.oppoSuitsForm.value.link;
    if(this.url == '')
    {
       this.url = "https://www.google.com/search?q=";
    }
    this.search = this.oppoSuitsForm.value.search;
   //alert(JSON.stringify(this.oppoSuitsForm.value.search))
    window.open(this.url + this.search);
  }

  ToggleButton(id:any,hashtag:any) {
    //alert(id);
    this.showSelected = !this.showSelected;
    this.platformIds = this.userService.getPlatformByHashtagId(id)
    .subscribe(platformArray => {
      this.arrays = platformArray.platform;
      this.arrays.filter((val:any) =>
      {
       // this.val = val
        if (val == 'TWT') 
         {
          this.twt = "https://twitter.com/hashtag/" +hashtag;
          this.islink = !this.islink;
       
         console.log(this.twt);
         }
        if(val =='IGM')
         {
          this.insta = "https://www.instagram.com/explore/tags/" +hashtag;
          this.islink = !this.islink;
       
    // console.log(this.insta);
         }
        if(val =='FBK')
         {
          this.fab = "https://www.facebook.com/hashtag/" +hashtag;
          this.islink = !this.islink;
       
       // console.log(this.fab);
         }
       if(val =='LKN')
         {
          this.lkn = "https://www.linkedin.com/feed/hashtag/" +hashtag;
          this.islink = !this.islink;
       
        console.log(this.lkn);
         }
        if(val =='YUB')
         {
          this.yub = "https://www.youtube.com/hashtag/" +hashtag;
          this.islink = !this.islink;
       
         console.log(this.yub);
         }
        if(val == null)
         {
          this.msg = "no platform";
           this.islink = !this.islink;
       
          console.log(this.msg);
         }
         
      });
      
      //console.log(i, el)
     
     // TWT, FBK, IGM, YTB,LKN
      //   if (el == 'TWT') {
      //  console.log("twitter");
      //   } 
      //   else if(el == 'FBK')
      //   {
      //     console.log("facebook");
      //   }  
      //   else if(el == 'IOJ')
      //   {
      //     console.log("mo");
      //   }
     
  
      this.platformArray = platformArray;
    });
 }
  // categoryListData(){
  //   this.categoryList= this.userService.CategoryList;
  //   console.log(this.categoryList);
  // }
}
