import { NgModule,Pipe } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { HomeComponent } from './home/home.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { RegisterComponent } from './register/register.component';
import { SignupComponent } from './signup/signup.component';
import {CategoryComponent} from './category/category.component';
import {VideoComponent} from './video/video.component';
import {VideosComponent} from './videos/videos.component';
import {FavouriteComponent} from './favourite/favourite.component';
import {OffersComponent} from './offers/offers.component';
import {StoreComponent} from './store/store.component';
import{ProfileComponent} from './profile/profile.component';
import {TrendpageComponent} from './trendpage/trendpage.component';
import { SubcategoryListComponent } from './subcategory-list/subcategory-list.component';
import{AllstoreComponent} from './allstore/allstore.component';
const routes: Routes = [
  { path: '', component: HomeComponent},
 
  {
    path:'forget-password',component:ForgetPasswordComponent

  },
  {
    path:'profile',component:ProfileComponent

  },
  {
    path:'login',component:SignupComponent
  },
  {
    path:'register',component:RegisterComponent
  },
  {
    path:'preloader',component:PreloaderComponent
  },
  {
    path:'Home',component:HomeComponent
  },
  {
    path:'category/:id',component:CategoryComponent,
    
  },
  {
    path:'subcategory/:id',component:SubcategoryListComponent
  },
  
  {
    path:'video',component:VideoComponent
  },
  {
    path:'videos',component:VideosComponent
  },
  {
    path:'trendpage',component:TrendpageComponent
  },
  {
    path:'favourite',component:FavouriteComponent
  },
  {
    path:'offers',component:OffersComponent
  },
  {
    path:'store/:id',component:StoreComponent
  },
  {path:'allstore',component:AllstoreComponent},
  { path: '**', component: HomeComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
