<!-- Login Page -->
<section class="banner-hero vh-100 scroll-y bg-dark">
    <div class="carb-bg-left"></div>
    <div class="background-opac">
      <div class="container h-100 text-white">
               <div class="row h-100 h-sm-auto">
                   <div class="col-12 col-md-8 col-lg-5 col-xl-4 mx-auto align-self-center text-center ncard" >
                           <div class="logo"> <a href="#"><img src="../assets/logo-home.png"></a> </div>
                           <form [formGroup] = 'registerForm' (ngSubmit)= 'register()'>
                       <h3 style="font-size:25px; margin-top:25px">Create account with us</h3>
                       <h3 style="font-size:25px; margin-top:25px">{{message}}</h3>
                       <div class="form-group">
                           <label for="username" class="sr-only">Username</label>
                           <input type="text" formControlName='user_name'id="username" class="form-control form-control-lg" placeholder="Username" required="" autofocus="">
                           <div *ngIf="registerForm.get('user_name').hasError('required') && registerForm.get('user_name').touched" style="color: #ffff;">user name Is Required</div>
                           <div *ngIf="registerForm.controls['user_name'].errors?.minlength" style="color: #ffff;">Minimum 4 Character</div>
                        </div>
                       <div class="form-group">
                           <label for="inputEmail" class="sr-only">Email address</label>
                           <input type="email" formControlName='user_email'id="inputEmail" class="form-control form-control-lg" placeholder="Email address" required="" autofocus="">
                           <div *ngIf="registerForm.get('user_email').hasError('required') && registerForm.get('user_email').touched" style="color: #ffff;">Email Is Required</div>
                        </div>
                       <div class="form-group">
                           <label for="inputPassword" class="sr-only">Password</label>
                           <input type="password" formControlName='password' id="inputPassword" class="form-control form-control-lg" placeholder="Password" required="">
                           <div *ngIf="registerForm.get('password').hasError('required') && registerForm.get('password').touched" style="color: #ffff;">Password Is Required</div>
                           <div *ngIf="registerForm.get('password').hasError('pattern') && registerForm.get('password').touched" style="color: #ffff;">Invalid password</div>
                        </div>
                       <div class="form-group">
                           <label for="inputPassword1" class="sr-only">Password</label>
                           <input type="password" formControlName='cpassword'id="inputPassword1" class="form-control form-control-lg" placeholder="Confirm Password" required="">
                       </div>
                       <div class="my-3">
                           <div class="custom-control custom-checkbox">
                               <input type="checkbox" class="custom-control-input" id="customCheck1" checked="">
                               <label class="custom-control-label" for="customCheck1">Agree to the <a href="t&c.html" class="login-txt">Terms & Condition</a> and <a href="privacy-policy.html" class="login-txt">Privacy Policy</a></label>
                               
                           </div>
                       </div>
                       <div class="mb-4">
                        <button type="submit" class="btn btn-lg btn-default default-shadow btn-block" click='register()'>Submit</button>
                        <!-- <p>Form value:{{registerForm.value|json}} type:hidden</p> 
                          -->
                       </div>
                       <div class="mb-4">
                        <p>
                            Already have account? Please <a routerLink="/login" class="login-txt">Sign In</a> here.
                        </p>
                    </div>
                      
                       </form>
                   </div>
               </div>
           </div>
    </div>
 </section>
<!-- Login Page --> 

