import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from './../user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm :FormGroup|any

  users:any;
  message:any;
  constructor(private fb:FormBuilder ,private userService:UserService ,private router:Router) { }

  ngOnInit(): void {
    this.registerForm = this.fb.group({
      user_name :[null,[Validators.required,Validators.minLength(4)]],
      user_email :[null ,[Validators.required,Validators.pattern("^([a-zA-Z0-9.-]+)@([a-zA-Z]{5,12}).([a-zA-Z.]{2,20})$")]],
      password:[null ,[Validators.required,Validators.pattern("^(?=.*[0-9])(?=.*[!@])[a-zA-Z0-9!@#$%^&*]{6,16}$")]],
      cpassword:[null ,[Validators.required]],
     })
  }

  register():void{
    let formObj = this.registerForm.getRawValue();
  //  console.log(formObj);
      this.userService.RegisterUser(formObj).subscribe(user=>{
       // console.log("status",user);
        if(user.status == true)
        {
         this.message =  user.message
        // console.log("status",user.status);
         this.router.navigate(['/login']);
        }
        if(user.status == false)
        {
          this.message =  user.message
          //console.log("status",user.status);
          this.router.navigate(['/register']);
        }
       //alert(this.users.message)
       // this.router.navigate(['/login']);
      })
 
     
  }

}
