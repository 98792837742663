import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from './../user.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  forgotPasswordForm :FormGroup|any
  changePassword:any
  message: any;
  constructor(private fb:FormBuilder ,private userService:UserService ,private router:Router) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      user_email :[null ,[Validators.required,Validators.pattern("^([a-zA-Z0-9.-]+)@([a-zA-Z]{5,12}).([a-zA-Z.]{2,20})$")]],
    })
  }

  resetPassword():void{
    let formObj = this.forgotPasswordForm.getRawValue();
  //console.log("dfdsfsd",formObj);
   this.userService.forgotPassword(formObj).subscribe(changePassword=>{

    if(changePassword.status == true)
        {
         this.message =  changePassword.message
        // console.log("status",user.status);
       // this.router.navigate(['/forget-password']);
        }
        if(changePassword.status == false)
        {
          this.message = changePassword.message
        //  this.router.navigate(['/forget-password']);
        }
      })
 
     
  }

}
