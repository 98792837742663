import { Category } from './../category';
import { SubCategory } from './../subCategory';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from './../user.service';
import {Router} from '@angular/router';
@Component({
  selector: 'app-subcategory-list',
  templateUrl: './subcategory-list.component.html',
  styleUrls: ['./subcategory-list.component.css']
})
export class SubcategoryListComponent implements OnInit {
  userInfo:any
  subCategoryDetails:any
  SubCategoryList:any
  Linklist:any
  id:any
   categoryList: string[] | any = []
  subscription: string[] | any = []
  products: string[] | any = []
  CategoryId: any
  linkDetails:any
  constructor(private route: ActivatedRoute, private userService:UserService, private router: Router) { }

  ngOnInit(): void {
 
    //  console.log("gfgf",cat_id);

  }

}
