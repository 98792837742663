<section class="category-page">
  <header class="topheader">
      <nav class="navbar">
          
        <span class="topbar-custom" onclick="openNav()">&#9776; </span>
          <div class="logo"> <a href="#"><img src="../assets/logo-home.png" style="margin-left: -10%;"></a></div>
           
            <div class="header-search">
              <div class="search-main dropdown"> 
                  <!-- <a href="javaScript:void(0)" class="toggle"><i class="fas fa-search"></i></a> -->
                <div class="dropdown-menu top-search">
                   <!-- <input type="text" id="somenth" class="form-control" placeholder="Search.." /> -->
                  <!-- <button type="submit" class="btn btn-submit"><i class="fas fa-search"></i></button> -->
                </div>
              </div>
              <!-- <a href="#" class=""> <span class="avatar avatar-30"><img src="../assets/user5.jpg" alt=""></span> </a>--> </div>  
      </nav>
    </header>
    <!-- Fixed navbar ends -->
  
  <!-- side navbar start -->
  <div id="mySidenav" class="sidenav">
    <div class="side-menu-wrap">        
      <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
      <div class="row no-gutters mb-4">
        <div class="col-auto align-self-center ml-3">
          <figure class="avatar avatar-50"> <img src="../assets/images/user5.png" alt=""> </figure>
        </div>
        <div class="col pl-3 align-self-center">
          <p class="my-0" style="color: black;"*ngIf="!user_id" >Hii There</p>
          <p class="my-0" style="color: black;"*ngIf="user_id" >{{currentUser}}</p>
        </div>
        <div class="col-auto align-self-center"> <a (click)='logout()'*ngIf="user_id" class="btn btn-link text-white p-2"><i class="fas fa-power-off"></i></a> </div>
      </div>
      <a routerLink="/" class="active"><i class="fas fa-home"></i>Home</a>
      <a href="#"><i class="fas fa-star"></i>Rate Us</a> 
      <!-- <a href="about-us.html"><i class="fas fa-address-card"></i>About Us</a>  -->
      <!-- <a href="contact.html"><i class="fas fa-address-book"></i>Contact Us</a>  -->
      <a href="#"><i class="fas fa-share-alt"></i>Share</a> 
      <a routerLink="/register"><i class="fas fa-sign-in-alt"></i>Sign up</a> 
      <a routerLink="/login" *ngIf="!user_id"><i class="fas fa-user"></i>Log In</a> 
     
      <a routerLink="/profile"><i class="fas fa-user-circle"></i>Profile</a> 
    </div>

</div>


<!-- side navbar end -->

<!-- Page Content -->
<section class="category-page">
    <div class="hot-topics trending-website">
      <div class="container">
        <h5 class="page-subtitle text-left">Added by Me</h5>
          <div class="row">
            <div class="col-4 col-md-3" *ngFor="let linkitem of favDeatils;">
              <div class="card border-0 shadow-light text-center mb-4">
               <a class="d-block" href="{{linkitem.website_link}}" targrt="_blank">
                  <div class="card-body position-relative">
                    <div class="h-100px position-relative overflow-hidden">
                      <div class="background background-h-100" style="background-image:  url({{linkitem.image_link}});"> </div>
                        <div class="edit-icon text-right float-right">
                            <div class="container popup">
                              <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                              </div></div>
                            
                       <div class="clearfix"></div>                       
                    </div>
                  </div>
               </a>
              </div>
              <div class="cat-name text-center">
                <p>{{linkitem.name}}</p>
               </div>
            </div>
          
          <div class="col-4 col-md-3">
            <div class="plus-icon">
              <a href="javascript:void(0);" data-toggle="modal" data-target="#myModal"><i class="fa fa-plus imgAdd"></i></a>
            </div>
          </div>
       </div>
  
       <div class="my-fav">
         <h5 class="page-subtitle text-left">My Favourite</h5>
         <div class="row" >
            <div class="col-4 col-md-3"  >
              <div class="card border-0 shadow-light text-center mb-4">
           
                  <div class="card-body position-relative">
                    <div class="h-100px position-relative overflow-hidden">
                      <div class="background background-h-100" style="background-image:   url(images/myntra.png);"> </div>
                        <!-- <div class="edit-icon text-left" data-toggle="modal" data-target="#myModal2"> -->
                            <!-- <i class="far fa-trash-alt"></i> -->
                             <div class="edit-icon text-right float-right">
                            <div class="container popup">
                              <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                              </div></div>
                        <!-- </div>                    -->
                    </div>
                  </div>
            
              </div>
              <div class="cat-name text-center">
                <p>Category</p>
                <p>Sub Category</p>
              </div>
            </div>
         </div>
       </div>
  
        <!-- <div class="row">
          <div class="col-6 col-md-6">
             <h6 class="page-subtitle text-left">All Websites</h6>
          </div>
          <div class="col-6 col-md-6">
             <div class="form-group">
                <select class="form-control mt-4">
                  <option>All Category</option>
                  <option>Fashion</option>
                  <option>Shopping</option>
                  <option>E-payment</option>
                  <option>News</option>
                  <option>Entertainment</option>
                  <option>Entertainment</option>
                  <option>Social & Blogs</option>
                  <option>Food & Grocery</option>
                  <option>Health</option>
                  <option>Finance</option>
                  <option>Jobs & Edu</option>
                </select>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4 col-md-3">
            <div class="card border-0 shadow-light text-center mb-4">
               <a href="#" class="d-block">
                 <div class="card-body position-relative">
                <div class="h-100px position-relative overflow-hidden">
                  <div class="background background-h-100" style="background-image: 
                                              url(images/myntra.png);"> 
                  </div>
                </div>
              </div>
               </a>
            </div>
          </div>
          <div class="col-4 col-md-3">
            <div class="card border-0 shadow-light text-center mb-4">
              <a href="#" class="d-block">
              <div class="card-body position-relative">
                <div class="h-100px position-relative overflow-hidden">
                  <div class="background background-h-100" style="background-image: 
                                              url(images/first-cry.png);"> </div>
                </div>
              </div>
              </a>
            </div>
          </div>
          <div class="col-4 col-md-3">
            <div class="card border-0 shadow-light text-center mb-4">
              <a href="#" class="d-block">
                <div class="card-body position-relative">
                <div class="h-100px position-relative overflow-hidden">
                  <div class="background background-h-100" style="background-image: 
                                              url(images/ShopClues.png);"> </div>
                </div>
              </div>
              </a>
            </div>
          </div>
          <div class="col-4 col-md-3">
            <div class="card border-0 shadow-light text-center mb-4">
               <a href="#" class="d-block">
                 <div class="card-body position-relative">
                <div class="h-100px position-relative overflow-hidden">
                  <div class="background background-h-100" style="background-image: 
                                              url(images/india-rush.png);"> </div>
                </div>
              </div>
               </a>
            </div>
          </div>
          <div class="col-4 col-md-3">
            <div class="card border-0 shadow-light text-center mb-4">
               <a href="#" class="d-block">
                 <div class="card-body position-relative">
                <div class="h-100px position-relative overflow-hidden">
                  <div class="background background-h-100" style="background-image: 
                                              url(images/jabong.png);"> </div>
                </div>
              </div>
               </a>
            </div>
          </div>
          <div class="col-4 col-md-3">
            <div class="card border-0 shadow-light text-center mb-4">
              <a href="#" class="d-block">
                <div class="card-body position-relative">
                <div class="h-100px position-relative overflow-hidden">
                  <div class="background background-h-100" style="background-image: 
                                              url(images/amazon.png);"> </div>
                </div>
              </div>
              </a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </section>
  
  <!-- Page Content End --> 
  
 

<!-- sticky footer tabs -->
<section id="foot">
  <div class="footer-tabs border-top text-center">
    <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" routerLink="/"><i class="fas fa-home"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/offers/"><i class="fas fa-handshake"></i>
        </a>
      </li>
      <li class="nav-item centerlarge">
      
        <a class="nav-link bg-default"  *ngIf="user_id" routerLink="/favourite"
          ><i class="fa fa-star"></i>
        </a>
        <a class="nav-link bg-default" *ngIf="!user_id" routerLink="/login"
          ><i class="fa fa-star"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/"><i class="fas fa-bell"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/store"><i class="fas fa-globe"></i>
        </a>
      </li>
      
    </ul>
  </div>
</section>

<!-- sticky footer tabs ends --> 
  <!-- scroll to top button -->
  <button type="button" class="btn btn-default scrollup position-fixed btn-44"> <i class="fas fa-chevron-up"></i> </button>
  <!-- scroll to top button ends--> 
  
  
  
  
  <!-- The Modal -->
  <div class="modal" id="myModal">
    <div class="modal-dialog">
      <div class="modal-content">
  
        <!-- Modal Header -->
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
          <form [formGroup] = 'favForm' (ngSubmit)= 'addFav()'>
           
              <div class="form-group">
                <input type="text"  formControlName='name'  class="form-control" placeholder="Name">
              </div>
              <div class="form-group">
                <input type="text"   formControlName='website_link' class="form-control" placeholder="URL">
              </div>
              <div class="form-group">
                <input type="text"  formControlName='image_link' class="form-control" placeholder="Image Link">
              </div>
              <input type='hidden' name='user_id'  value='{{user_id}}'/>
              <button type="submit" class="btn btn-primary" id ="modelclose"  style="background-color:green;" click='addFav()'  >Submit</button>
          </form>
        </div>
  
        <!-- Modal footer -->
        <div class="modal-footer">
          <button type="button"   class="btn btn-danger" data-dismiss="modal" style="background-color: red; color: white;">Close</button>
          <!-- <p>Form value:{{favForm.value|json}}</p>  -->
        </div>
  
      </div>
    </div>
  </div>
  
  
  
  <!-- The Modal -->
  <div class="modal" id="myModal2">
    <div class="modal-dialog modal-sm mt-5">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body text-center">
          Do you really wish to remove this website from your Favourite list ?
        </div>
        <div class="text-center mb-3 ">
          <button type="button" class="btn btn-info">Confirm</button>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer py-2">
          <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
        </div>
  
      </div>
    </div>
  </div>
