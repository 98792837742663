import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { ReactiveFormsModule, FormsModule,FormGroup } from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { SignupComponent } from './signup/signup.component';
import { RegisterComponent } from './register/register.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { HomeComponent } from './home/home.component';
import { CategoryComponent } from './category/category.component';
import { VideoComponent } from './video/video.component';
import { FavouriteComponent } from './favourite/favourite.component';
import { OffersComponent } from './offers/offers.component';
import { ProfileComponent } from './profile/profile.component';
import { StoreComponent } from './store/store.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { VideosComponent } from './videos/videos.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TrendpageComponent } from './trendpage/trendpage.component';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule} from '@angular/common/http'
import { AuthInterceptor } from './jwtinterceptor';
import { SubcategoryListComponent } from './subcategory-list/subcategory-list.component';
import { SlicePipe } from './slice.pipe';
import { StoreListComponent } from './store-list/store-list.component';
import { AllstoreComponent } from './allstore/allstore.component';



@NgModule({
  declarations: [
    AppComponent,
    ForgetPasswordComponent,
    SignupComponent,
    RegisterComponent,
    PreloaderComponent,
    HomeComponent,
    CategoryComponent,
    VideoComponent,
    FavouriteComponent,
    OffersComponent,
    ProfileComponent,
    StoreComponent,
    VideosComponent,
    TrendpageComponent,
    SubcategoryListComponent,
    SlicePipe,
    StoreListComponent,
    AllstoreComponent,
   
  
       
  ],
  imports: [
    BrowserModule,
    MatTabsModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'My-Xsrf-Cookie',
      headerName: 'My-Xsrf-Header',
    }),
    
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  //providers: [{provide:HTTP_INTERCEPTORS ,useClass:AuthInterceptor , multi:true}],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  //providers: [{provide: {LocationStrategy,HTTP_INTERCEPTORS}, useClass: AuthInterceptor},HashLocationStrategy],
  bootstrap: [AppComponent]
})
export class AppModule { }
export class TabGroupBasicExample {}
