
<!-- profile Page -->
<section class=" bg-dark">
  <div class="">
    <div class="container h-100 text-white body-custom">
             <div class="row h-100 h-sm-auto">
           
                 <div class="col-12 col-md-8 col-lg-5 col-xl-4 mx-auto align-self-center text-center" *ngIf = "profileById">
                     <!-- <div class="logo"> <a href="#"><img src="../assets/logo-home.png"></a> </div> -->
                     
                     <h3 class="font-weight-normal mb-4 mt-4">Profile Information</h3>
                     <h3 class="font-weight-normal mb-4 mt-4">   {{res}}</h3>
                     
                     <form [formGroup] = "profileForm" (ngSubmit)= "updateProfile()">
                     <div class="form-group">
                         <div class="profile-pic">            
                             <a href="javascript:void(0);" class="tooltips" title="Click To Zoom">
                                 <img id="imgLogo" src="../assets/images/user5.png" alt="Profile Picture">
                             </a>
                             <input type="file"  formControlName="user_image" id="file-upload" class="profile-pic-inputfile" accept=".jpg,.jpeg,.png" (change)="SelectImage($event)">
                             <label for="file-upload"><span><i class="fas fa-pencil-alt"></i></span></label>
                             <!-- <input type="file" class="form-control" (change)="SelectImage($event)"  name="user_image" id="photo" formControlName="user_image">-->
                            
                         </div>  
                     </div>
                        <div class="form-group" >
                         <label for="inputName" class="sr-only">User Name</label>
                         <input type="text"  formControlName="user_name"  class="form-control form-control-lg border-0"  [(ngModel)]="profileById.user_name">
                     </div>
                     <div class="form-group">
                      <label for="phone" class="sr-only">Enter a phone number:</label>
                      <input type="tel" id="phone" class="form-control form-control-lg border-0" formControlName="user_phone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required [(ngModel)]="profileById.user_phone">
                      </div>
                      <div class="form-group">
                         <label for="inputEmail" class="sr-only">Email</label>
                         <input type="text" id="inputemail"formControlName="user_email" [(ngModel)]="profileById.user_email" class="form-control form-control-lg border-0" required="">
                     </div>

                    <input type="hidden"  [(ngModel)]="profileById.id" formControlName= "user_id">
                     <!-- <label for="textbox"></label>
                     <textarea  name="textarea" rows="4" cols="0" placeholder="Email address" class=" form-control form-control-lg" style="margin-bottom: 10px;MARGIN-TOP:-25px;">
                      Enter your address here..
                      </textarea>-->
                      <div class="form-group">
                        <!-- <select name="country" id="countySel" size="1" class="form-control form-control-lgl" style=" height: 37px;" [(ngModel)]="country.countryname">

                          <option [value]="countries.id" *ngFor="let countries of country">{{countries.countryname}}</option>
                        </select> -->
                       <select id="countySel" size="1" class="form-control form-control-lg" formControlName="country" >

                         <option  value ="">{{profileById.country}}</option>
                        <option  *ngFor="let countries of country; let i = index;" value="{{countries.countryname}}"  [selected]="countries.id == profileById.country" >{{countries.countryname}}</option>
                      
                            </select>
                            <input type="hidden"  *ngIf= "profileById.country;" [(ngModel)]="profileById.country" formControlName= "country">
                     </div>

                     <div class="form-group">
                      <label for="inputstate" class="sr-only">State</label>
                      <input type="text" id="inputstate"formControlName="state" [(ngModel)]="profileById.state" class="form-control form-control-lg border-0" required="">
                   </div>

                   <div class="form-group">
                    <label for="inputCity" class="sr-only">City</label>
                    <input type="text" id="inputCity"formControlName="city" [(ngModel)]="profileById.city" class="form-control form-control-lg border-0" required="">
                 </div>
                          <br> 
                            <!-- <div class="form-group">
                            <select id="stateSel" size="1"class="form-control form-control-lg">
                                <option value="" selected="selected">-- Select State--</option>
                                <option value="wb">-- W B--</option>                                   
                                <option value="wb">-- W B--</option>
                            </select>
                          </div> -->
                         


                            <!-- <div class="form-group">
                               
                            <select id="citySel" size="1"class="form-control form-control-lg">
                                <option value="" selected="selected">-- Select City--</option>
                                <option value="">-- kolkata--</option>
                                <option value="">-- kolkata--</option>
                            </select>
                          </div> -->
                           
                             
                      <!-- <div class="form-group">
                          <label for="oldpassword" class="sr-only">Old Password</label>
                          <input type="password" id="pwd" name="pwd" class="form-control form-control-lg border-0" placeholder="Enter your Old password">
                      </div> -->
                      <!-- <div class="form-group">
                          <label for="newpassword" class="sr-only">New Password</label>
                          <input type="password" id="pwd" name="pwd" class="form-control form-control-lg border-0" placeholder="Enter your New password">
                      </div> -->
                    <!--<div class="form-group ">
                      <form name="myform" id="myForm">
                          <select id="countySel" size="1" class="form-control form-control-lg">
                                <option value="" selected="selected">-- Select Country --</option>
                            </select>
                             <br>
                           
                          
                            <select id="stateSel" size="1"class="form-control form-control-lg">
                                <option value="" selected="selected">-- Select State--</option>
                            </select>
                            <br>
                               
                            <select id="citySel" size="1"class="form-control form-control-lg">
                                <option value="" selected="selected">-- Select City--</option>
                            </select>
                            <br>
                           
                            <select id="zipSel" size="1"class="form-control form-control-lg">
                                <option value="" selected="selected">-- Select Zip--</option>
                            </select>
                        </form>
                     </div>  -->
                     <!-- <table class="table table-borderless text-white text-left">
                         <tbody>
                           <tr>
                             <th>User :</th>
                             <td>Test</td>
                           </tr>
                           <tr>
                             <th>Email id :</th>
                             <td>john@example.com</td>
                           </tr>
                           <tr>
                             <th>Join Date :</th>
                             <td>Test</td>
                           </tr>
                         </tbody>
                       </table> -->

                     <div class="mb-4">
                      <button type="submit" class=" btn btn-lg btn-default default-shadow btn-block" style="margin-left:15px; width:76%"> Update <span class="ml-2 icon arrow_right"></span></button>
                         <!-- <a routerLink="forget-password" class=" btn btn-lg btn-default default-shadow btn-block">Update <span class="ml-2 icon arrow_right"></span></a> -->
                     </div>
                     </form>
                 </div>
              
             </div>
         </div>
  </div>
</section>
<!-- profile Page end --> 