<section class="category-page">
    <header class="topheader">
        <nav class="navbar">
            
          <span style="font-size:30px;cursor:pointer;color: #f44336" onclick="openNav()">&#9776; </span>
              <div class="logo"> <a href="#"><img src="../assets/logo-home.png" style="margin-left: -10%;"></a></div>
             
              <div class="header-search">
                <div class="search-main dropdown"> 
                    <!-- <a href="javaScript:void(0)" class="toggle"><i class="fas fa-search"></i></a> -->
                  <div class="dropdown-menu top-search">
                     <!-- <input type="text" id="somenth" class="form-control" placeholder="Search.." /> -->
                    <!-- <button type="submit" class="btn btn-submit"><i class="fas fa-search"></i></button> -->
                  </div>
                </div>
                <!-- <a href="#" class=""> <span class="avatar avatar-30"><img src="../assets/user5.jpg" alt=""></span> </a>--> </div>  
        </nav>
      </header>
      <!-- Fixed navbar ends -->
    
 <!-- side navbar start -->
 <div id="mySidenav" class="sidenav">
  <div class="side-menu-wrap">        
    <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
    <div class="row no-gutters mb-4">
      <div class="col-auto align-self-center ml-3">
        <figure class="avatar avatar-50"> <img src="../assets/images/user5.png" alt=""> </figure>
      </div>
      <div class="col pl-3 align-self-center">
        <p class="my-0" style="color: black;"*ngIf="!user_id" >Hii There</p>
        <p class="my-0" style="color: black;"*ngIf="user_id" >{{currentUser}}</p>
      </div>
      <div class="col-auto align-self-center"> <a (click)='logout()'*ngIf="user_id" class="btn btn-link text-white p-2"><i class="fas fa-power-off"></i></a> </div>
    </div>
    <a routerLink="/" class="active"><i class="fas fa-home"></i>Home</a>
    <a href="#"><i class="fas fa-star"></i>Rate Us</a> 
    <!-- <a href="about-us.html"><i class="fas fa-address-card"></i>About Us</a>  -->
    <!-- <a href="contact.html"><i class="fas fa-address-book"></i>Contact Us</a>  -->
    <a href="#"><i class="fas fa-share-alt"></i>Share</a> 
    <a routerLink="/register"><i class="fas fa-sign-in-alt"></i>Sign up</a> 
    <a routerLink="/login" *ngIf="!user_id"><i class="fas fa-user"></i>Log In</a> 
    <a routerLink="/login" *ngIf="!user_id"><i class="fas fa-user-circle"></i>Profile</a> 
      <a routerLink="/profile" *ngIf="user_id"><i class="fas fa-user-circle"></i>Profile</a> 
  </div>

</div>

<!-- side navbar end -->
    

<!-- Latest News start -->
<div class="latest-news">
    <div class="container">
      <h5 class="page-subtitle">Trending Topics 
        <!-- <a href="#" class="btn btn-sm float-right px-0">See all</a> -->
      </h5>
      <div class="row">
        <div class="col-12 col-sm-12" *ngFor="let item of topic;">

          <a href="#">
          <div class="card mb-4 shadow-light  border-1" >
            <div class="card-img"> <img src="{{item.img_path}}" alt=""  class="rounded-left">
            
              <h4 class="mb-1 text-custom">{{item.title}}</h4>
            
            </div>
            <div class="card-text"  style="display:none;">
               <!-- <span class="badge badge-pill badge-primary mb-2 mr-2">Politics</span>
                <span class="badge badge-pill badge-success mb-2">politics</span> 
                 <a href="#" class="float-right px-0">00:00 PM</a> -->
                
              <!-- <p class="mb-0 small">By Maxartkiller, 7-11-2018 | Public</p> -->
              <!-- <div class="float-left">
                <img src="../assets/images/india-today.jpg" alt="" />
              </div> -->
              <div class="float-right">
                <a class="read-more" href="#"  style="color:#f44336;margin-top:35px; font-size: 12px;">Read More</a>
              </div>
            </div>
          </div>
          </a>

        </div>
      </div>
    </div>
  </div>
 </section>
<!-- Latest News start - ->


<!-- sticky footer tabs -->
<section id="foot">
  <div class="footer-tabs border-top text-center">
    <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" routerLink="/"><i class="fas fa-home"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/offers/"><i class="fas fa-handshake"></i>
        </a>
      </li>
      <li class="nav-item centerlarge">
      
        <a class="nav-link bg-default"  *ngIf="user_id" routerLink="/favourite"
          ><i class="fa fa-star"></i>
        </a>
        <a class="nav-link bg-default" *ngIf="!user_id" routerLink="/login"
          ><i class="fa fa-star"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/"><i class="fas fa-bell"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/store"><i class="fas fa-globe"></i>
        </a>
      </li>
      
    </ul>
  </div>
</section>

<!-- sticky footer tabs ends --> 