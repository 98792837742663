import { Observable } from 'rxjs';
import { Category } from './../category';
import { SubCategory } from './../subCategory';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from './../user.service';
import { AuthenticationService } from '../authentication.service';
import {Router} from '@angular/router';
import { empty } from 'angular-pipes/utils/utils';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {
  currentUser: string[] | any = []
  user_id :any
  offerDetails:any
  offerList:any
  categoryList: string[] | any = []
  subscription: string[] | any = []
  subCategoryDetails:any
  SubCategoryList:any
  categorId:any
  message:any

  constructor(private route: ActivatedRoute, private userService:UserService, private router: Router,  private auth: AuthenticationService) 
  { 
           /**
     * OfferList
     */
        this.offerList = this.userService.offer().subscribe(offerDetails => {
          console.log("pppp",offerDetails);
          this.offerDetails = offerDetails;
          offerDetails.forEach((Category: { category_id: any; }) => {
            this.userService.getOfferById(Category.category_id).subscribe(SubCategoryList=>{
              if(!SubCategoryList.data)
              {
                this.message = null;
                //console.log("sfsdgdgdf",SubCategoryList.data);
               // this.message = "Currently, no deals available. Come back later to catch up";
              }
              //     console.log("offer",SubCategoryList.data);
                 this.SubCategoryList= SubCategoryList.data
                // this.router.onSameUrlNavigation = 'reload';
            });
            
          });
   })

  
    
      // this.subCategoryDetails= this.userService.getOfferById(4).subscribe(SubCategoryList=>{
      //  // console.log("offer",SubCategoryList.data);
      //  this.SubCategoryList= SubCategoryList.data
     
      // });
  
  }

  ngOnInit(): void {
    this.user_id = window.sessionStorage.getItem('id');

    this.currentUser = this.auth.getAllData();
    //console.log(this.currentUser);
    this.currentUser;

    



}
getSubCategory(CategoryId:any)
{

  //alert(CategoryId);
   this.categorId = CategoryId
  this.subCategoryDetails= this.userService.getOfferById(CategoryId).subscribe(SubCategoryList=>{
    console.log("offer",SubCategoryList.data);
    if(!SubCategoryList.data)
    {
      this.message = null;
      //console.log("sfsdgdgdf",SubCategoryList.data);
     // this.message = "Currently, no deals available. Come back later to catch up";
    }
    //     console.log("offer",SubCategoryList.data);
       this.SubCategoryList= SubCategoryList.data
      // this.router.onSameUrlNavigation = 'reload';
      });
}
  logout() {
    this.auth.destroyToken();
    this.router.navigate(['/login']);
  }

}
