import { Category } from './../category';
import { SubCategory } from './../subCategory';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from './../user.service';
import { AuthenticationService } from '../authentication.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})


export class CategoryComponent implements OnInit {
  categoryId: any;
  selected: any;
 

  constructor(private route: ActivatedRoute, private userService:UserService, private router: Router,  private auth: AuthenticationService) {
 
    
   }
  currentUser: string[] | any = []
  user_id :any
  subCategoryDetails:any
  SubCategoryList:any
  Linklist:any
  id:any
   categoryList: string[] | any = []
  subscription: string[] | any = []
  products: string[] | any = []
  CategoryId: any
  linkDetails:any


  ngOnInit(): void {
    this.user_id = window.sessionStorage.getItem('id');
    this.currentUser = this.auth.getAllData();
    //console.log(this.currentUser);
    this.currentUser;
      /**
      * Category List
      */
       const category = this.route.snapshot.params.id;
        this.subscription = this.userService.CategoryList().subscribe(products => {
        this.products = products;
        });
        this.subCategoryDetails= this.userService.subCategoryList(category).subscribe(SubCategoryList=>{
          this.SubCategoryList= SubCategoryList
          const  subitem = SubCategoryList[0];
        
          this.userService.getLinkBySubId(subitem.id).subscribe(data=>{
            this.Linklist = data;
        
        });

        });

  }

  getSubcategory(CategoryId:any)
  {  
     
         this.subCategoryDetails= this.userService.subCategoryList(CategoryId).subscribe(SubCategoryList=>{
         this.SubCategoryList= SubCategoryList
        // console.log('storeId', SubCategoryList[0]);
        const  subitem = SubCategoryList[0];
        
          this.userService.getLinkBySubId(subitem.id).subscribe(data=>{
            this.Linklist = data;
        
        })
      
        });
  }

  logout() {
    this.auth.destroyToken();
    this.router.navigate(['/login']);
  }


     
     activevatedAdmin(e: any) {
      //  alert(e.target.value);
        
        const value = e.target.value;
        this.selected = value;
        this.userService.getLinkBySubId(value).subscribe(data=>{
          // console.log("dta",data);
           this.Linklist = data;
        });
      }

    
}



