import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from './../user.service';
@Component({
  selector: 'app-allstore',
  templateUrl: './allstore.component.html',
  styleUrls: ['./allstore.component.css']
})
export class AllstoreComponent implements OnInit {
  websiteList:string[]|any=[]
  websiteLists:string[] | any = []
  storeList: any;
  id: any;
  storeSubCategory: any;
  subCategory: any;
  user_id: any;
  currentUser: any;
  items: any;
  key: any;
  urlBySubTitle: any;
  urlVal: any;
  urlValue: any;
  storeId: any;
  storeLogo: any;
  storeUrl: any;
  getStoreLists: any;
  getAllDeals: any;
  storeCategory: any;
  store_id: any;
  oppoSuitsForm: any;
  selected: any;
  searchStore: FormGroup|any;
  submitted: boolean|any;
  search: any;
  constructor(private route: ActivatedRoute,  private auth: AuthenticationService,private router: Router,private fb:FormBuilder,  private userService: UserService,) { }

  ngOnInit(): void {
    this.user_id = window.sessionStorage.getItem('id');
    this.currentUser = this.auth.getAllData();
    this.searchStore = this.fb.group({
      search: ['', [Validators.required]],
     
    })
    /**
     * Websites  List
     */
     this.websiteList = this.userService.TrendingWebsitesList()
     .subscribe(websiteLists => {
       this.websiteLists = websiteLists;
     });
    
  }

  logout() 
  {
    this.auth.destroyToken();
    this.router.navigate(['/login']);
  }

  onSubmit() {

    this.submitted = true;
    let formObj =  this.searchStore.getRawValue();
    this.userService.searchStores(formObj).subscribe(websiteLists=>{
      console.log(websiteLists);
      this.websiteLists = websiteLists;
    });
  
  
  }

 

}
