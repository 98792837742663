<section class="category-page">
  <header class="topheader">
      <nav class="navbar">
          
        <span class="topbar-custom" onclick="openNav()">&#9776; </span>
        <div class="logo"> <a href="#"><img src="../assets/logo-home.png" style="margin-left: -10%;"></a></div>
           
            <div class="header-search">
              <div class="search-main dropdown"> 
                
                <div class="dropdown-menu top-search">
               
                </div>
              </div>
              <!-- <a href="#" class=""> <span class="avatar avatar-30"><img src="../assets/user5.jpg" alt=""></span> </a>--> </div>  
      </nav>
    </header>
    <!-- Fixed navbar ends -->
  
  <!-- side navbar start -->
    <div id="mySidenav" class="sidenav">
      <div class="side-menu-wrap">        
        <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
        <div class="row no-gutters mb-4">
          <div class="col-auto align-self-center ml-3">
            <figure class="avatar avatar-50"> <img src="../assets/images/user5.png" alt=""> </figure>
          </div>
          <div class="col pl-3 align-self-center">
            <p class="my-0" style="color: black;">Hi there!</p>
          </div>
          <div class="col-auto align-self-center"> <a routerLink="/signup" class="btn btn-link text-white p-2"><i class="fas fa-power-off"></i></a> </div>
        </div>
        <a routerLink="/" class="active"><i class="fas fa-home"></i>Home</a>
        <a href="#"><i class="fas fa-star"></i>Rate Us</a> 
        <!-- <a href="about-us.html"><i class="fas fa-address-card"></i>About Us</a>  -->
        <!-- <a href="contact.html"><i class="fas fa-address-book"></i>Contact Us</a>  -->
        <a href="#"><i class="fas fa-share-alt"></i>Share</a> 
        <a routerLink="/register"><i class="fas fa-sign-in-alt"></i>Sign up</a> 
        <a routerLink="/login"><i class="fas fa-user"></i>Log In</a> 
        <a routerLink="/login" *ngIf="!user_id"><i class="fas fa-user-circle"></i>Profile</a> 
        <a routerLink="/profile" *ngIf="user_id"><i class="fas fa-user-circle"></i>Profile</a> 
      </div>
  
  </div>
  <!-- side navbar end -->



<!--category_banner-->
<div>
<div class="store_banner">
    <div class="container-fluid">
        <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7 padding_less">
            <div class="store-picture">
             <table>
               <tr>
                 <td>
                <img src="{{storeLogo}}" alt="store_picture"/>
              </td>
            <td>
               <a href="{{storeUrl}}" target="_blank"><i class="fa fa-paper-plane star" aria-hidden="true" ></i></a>
              </td>
              </tr>
              </table>
              </div>
           
            <!-- <i class="fa fa-paper-plane star" aria-hidden="true" ></i> -->
        </div>
       
    </div>
     

    <div class="switch-store">switch store</div>

    <div class="col-8 col-md-8 dropdown-custom-maindiv">

      <div class="form-group">
        <select id="stateSel" size="1" class="form-control" (change)="getStore($event)">
           
            <option *ngFor="let item of getStoreLists;" [selected]="item.id == selected" value="{{item.id}}"> {{item.name}} </option>                                 
            
        </select>
      </div>
   




      <div class="dropdown-div-1" style="display:none;">
      
          <div class="form-group">
            <select id="stateSel" size="1" class="form-control" (change)="getStore($event)">
                <option *ngFor="let item of getStoreLists;" value="{{item.id}}" [selected]="item.id == selected" value="{{item.id}}"> {{item.name}} </option>                                                 
            </select>
          </div>
           
        </div>
     </div>

    <!-- deals and offers tab -->

<div class="tab">
    <button class="tablinks active deal-left" onclick="openCity(event, 'London')" id="defaultOpen">Deals</button>
    
    <button class="tablinks deal-right" onclick="openCity(event, 'Tokyo')">Hot Links</button>
  </div>
  
  <div id="London" class="tabcontent" style="display: block;">
    <!-- <h3>Deals & Offers</h3> -->
    <section>
    
        <div class="container" style="margin-bottom: 50px;">
          <!-- <h5 class="page-subtitle">Deals and Offers</h5> -->
           <div class="container" style="margin-top: 15px;">
      <div class="row">
       <div class="tab-content" id="myTabContent">
         <div class="tab-pane fade was-validated active show" id="details" role="tabpanel"           aria-labelledby="details-tab">
            <div class="hot-topics trending-website">
                <div class="row">
             <div class="col-6 col-md-3" *ngFor="let item of getAllDeals;" >
               <div class="card border-0 shadow-light text-center mb-4 card-test">
                <div class="card-body position-relative">
                  <div class="white-bg" >
                       <img *ngIf="item.image_path; else  elseBlock" src="{{item.image_path}}" alt="" class="img-deals">
                       <ng-template #elseBlock>  <img src="https://coupon.2il.org/public/images/deal/{{item.image}}" alt=""></ng-template>
                       <div class="white-bg-txt">
                        <span class="excerpt">
                         
                          <span> {{item.title | customPipe:0:30}} </span>
                        </span>
                          <div class="text-center mt-3">
                             <div class="edit-icon text-right float-right">
                                 <div class="container popup popup-custom">
                                  <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                                   </div></div>
                                   <div class="card-footer" >
                                   <div class="separator clear-left"  >
                             <img src="{{storeLogo}}" alt="" class="w-100">
                          </div></div>
                       </div></div>
                  </div>
                </div>
              </div>
            </div>
            
      </div>
       </div>
       </div>
       </div>
       <div class="row">
        <div class="col-6 col-md-6" *ngIf="!getAllDeals?.length" >
       
              <div class="white-bg">
               <span style="text-align: center;">Currently, no deals available. Come back later to catch up</span> 
              </div >
            
         </div>
       
       </div>
   
       
   
      </div>
   
     </div> 
    
   </div>
   </section> 
   
  </div>
  
  


<!-- scroll to top button -->
<button type="button" class="btn btn-default scrollup position-fixed btn-44" style="background-color: aqua;"> <i class="fas fa-chevron-up"></i> </button>
<!-- scroll to top button ends--> 
  
  
  <div id="Tokyo" class="tabcontent">
    <!-- <h3>Hot Links</h3> -->
    <!--Accordion wrapper-->
<div class="accordion md-accordion accordion-4" id="accordionEx2" role="tablist" aria-multiselectable="true">

    <!-- Accordion card -->
    <div class="card hotlink-card" style="margin-top: 15px; background-color: rgb(0 0 0 / 0%);"  *ngFor="let item of storeCategory  | keyvalue; let i = index;">
  
      <!-- Card header -->
      <div class="card-header-custom z-depth-1 teal lighten-4" role="tab" id="heading10" >
        <a href="#" target="_blank" ><button type="button" class="btn btn-outline-primary-custom" >Visit</button></a>
         <a data-toggle="collapse" data-parent="#accordionEx2" href="{{'#collapse1'+i}}" aria-expanded="true"
          aria-controls="collapse10">
         <!-- <button (click)="deleteAdmin(item)">{{item.key}}</button> -->
          <h4 class="mb-0 black-text text-left font-weight-normal text-uppercase" >
          <span (click)="getSubcategory(item)">{{item.key}}</span>                         
          </h4>
        </a>
      </div>
  
      <!-- Card body -->
  
      <div id= "{{'collapse1'+i}}" class="collapse" role="tabpanel" aria-labelledby="heading10"
        data-parent="#accordionEx2" >
        <div class="card-body rgba-teal-strong white-text" *ngFor="let Subcategory of items let i = index;" >
            <ul class="tracks">
                <li> <a (click)="getURL(Subcategory,store_id)" class="red-text">{{Subcategory}}<span style="float: right;"><i class="fa fa-link" aria-hidden="true" ></i></span></a></li>
               
                </ul>
        </div>
      </div>
     
    </div>
    <!-- Accordion card -->
  
  </div>  
  </div>
<!-- sticky footer tabs -->
<section id="foot">
  <div class="footer-tabs border-top text-center">
    <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" routerLink="/"><i class="fas fa-home"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/offers/"><i class="fas fa-handshake"></i>
        </a>
      </li>
      <li class="nav-item centerlarge">
      
        <a class="nav-link bg-default"  *ngIf="user_id" routerLink="/favourite"
          ><i class="fa fa-star"></i>
        </a>
        <a class="nav-link bg-default" *ngIf="!user_id" routerLink="/login"
          ><i class="fa fa-star"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/"><i class="fas fa-bell"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/store"><i class="fas fa-globe"></i>
        </a>
      </li>
      
    </ul>
  </div>
</section>

<!-- scroll to top button -->
<button type="button" class="btn btn-default scrollup position-fixed btn-44" style="background-color: aqua;"> <i class="fas fa-chevron-up"></i> </button>
<!-- scroll to top button ends--> 