import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from './../user.service';
import * as $ from 'jquery';// import Jquery here  
import * as bootstrap from "bootstrap";

@Component({
  selector: 'app-favourite',
  templateUrl: './favourite.component.html',
  styleUrls: ['./favourite.component.css']
})
export class FavouriteComponent implements OnInit {
  user_id:string|any
  favForm :FormGroup|any
  users:any;
  favDeatilById:any;
  favDeatils:any;
  currentUser:any
  $:any;
  close:any
  closeModalEvent: any;
  constructor(private fb:FormBuilder ,private router: Router ,private auth :AuthenticationService,private userService:UserService) { }

  ngOnInit(): void {
    this.currentUser = this.auth.getAllData();
    //console.log(this.currentUser);
    this.currentUser;
    this.user_id = window.sessionStorage.getItem('id')  ;
    this.favForm = this.fb.group({
      user_id :[this.user_id,[Validators.required,Validators.minLength(4)]],
      name :[null,[Validators.required,Validators.minLength(4)]],
      website_link :[null,[Validators.required,Validators.minLength(4)]],
      image_link :[null,[Validators.required,Validators.minLength(4)]],
    })

    const user_id = window.sessionStorage.getItem('id');
   // console.log("gfgf",user_id);
        this.favDeatilById= this.userService.FavouriteDetails(user_id).subscribe(favDeatils=>{
            this.favDeatils= favDeatils;
        console.log("profile",favDeatils);
       });

  }

  // Navigate(val: string) {
  //   this._router.navigate([val])
  // }
  addFav():void{
    let formObj = this.favForm.getRawValue();
   
    console.log(formObj);
      this.userService.AddUserFav(formObj).subscribe(user=>
        {
        this.users = user
        
        // this.closeModalEvent.emit(false);  
       // this.ngOnInit();
       // alert(this.users.message)
      })
 
     
  }

  
  logout() {
    this.auth.destroyToken();
    this.router.navigate(['/login']);
  }

}
