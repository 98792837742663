import { Component, OnInit } from '@angular/core';
import { UserService } from './../user.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthguardService } from '../authguard.service';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  loginForm :FormGroup|any
  user_data:any;
  message: string|any;
  constructor(private fb: FormBuilder,
    private auth:AuthenticationService,  
    private userService:UserService,
    public router:Router) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      user_email :[null ,[Validators.required]],
      password :[null ,[Validators.required]]
    })

    
  }
  loginUser():void{
    let formObj = this.loginForm.getRawValue();
    
    this.userService.LoginUserInfo(formObj).subscribe(user=>{
      this.user_data = user;
      
      console.log("userdata" ,this.user_data);
   
    //  alert(this.user_data.message);
 
                if(this.user_data.status == true){
               
                  this.router.navigate(['/home']);
                  return this.auth.setTokenInitialData(this.user_data.token , this.user_data.email , this.user_data.name,this.user_data.id);
                  // console.log("ghhg",window.localStorage.setItem('token', this.user_data.name))
        // window.localStorage.setItem('token', this.user_data.token)
        // window.localStorage.setItem('userId', this.user_data.email)
        // window.localStorage.setItem('token', this.user_data.name)
      }
      if(this.user_data.status == false){
        this.message = user.message;
       // alert(this.user_data.message);
       // return false;
      }
      
       
     
       
      

    })
  }

}
