<section class="category-page">
<header class="topheader">
    <nav class="navbar">
        
      <span class="topbar-custom" onclick="openNav()">&#9776; </span>
          <div class="logo"> <a href="#"><img src="../assets/logo-home.png" style="margin-left: -10%;"></a></div>
         
          <div class="header-search">
            <div class="search-main dropdown"> 
                <!-- <a href="javaScript:void(0)" class="toggle"><i class="fas fa-search"></i></a> -->
              <div class="dropdown-menu top-search">
                 <!-- <input type="text" id="somenth" class="form-control" placeholder="Search.." /> -->
                <!-- <button type="submit" class="btn btn-submit"><i class="fas fa-search"></i></button> -->
              </div>
            </div>
            <!-- <a href="#" class=""> <span class="avatar avatar-30"><img src="../assets/user5.jpg" alt=""></span> </a>--> </div>  
    </nav>
  </header>
  <!-- Fixed navbar ends -->

<!-- side navbar start -->
  <div id="mySidenav" class="sidenav">
    <div class="side-menu-wrap">        
      <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
      <div class="row no-gutters mb-4">
        <div class="col-auto align-self-center ml-3">
          <figure class="avatar avatar-50"> <img src="../assets/images/user5.png" alt=""> </figure>
        </div>
        <div class="col pl-3 align-self-center">
          <p class="my-0" style="color: black;"*ngIf="!user_id" >Hii There</p>
          <p class="my-0" style="color: black;"*ngIf="user_id" >{{currentUser}}</p>
        </div>
        <div class="col-auto align-self-center"> <a (click)='logout()'*ngIf="user_id" class="btn btn-link text-white p-2"><i class="fas fa-power-off"></i></a> </div>
      </div>
      <a routerLink="/" class="active"><i class="fas fa-home"></i>Home</a>
      <a href="#"><i class="fas fa-star"></i>Rate Us</a> 
      <!-- <a href="about-us.html"><i class="fas fa-address-card"></i>About Us</a>  -->
      <!-- <a href="contact.html"><i class="fas fa-address-book"></i>Contact Us</a>  -->
      <a href="#"><i class="fas fa-share-alt"></i>Share</a> 
      <a routerLink="/register"><i class="fas fa-sign-in-alt"></i>Sign up</a> 
      <a routerLink="/login" *ngIf="!user_id"><i class="fas fa-user"></i>Log In</a> 
      <a routerLink="/login" *ngIf="!user_id"><i class="fas fa-user-circle"></i>Profile</a> 
      <a routerLink="/profile" *ngIf="user_id"><i class="fas fa-user-circle"></i>Profile</a> 
    </div>

</div>


<!-- side navbar end -->

<!-- Page Content -->

  <div > 
    <form [formGroup] = "oppoSuitsForm" (ngSubmit)= "onSubmit()">
    <div class="mb-3 mt-5 position-relative multi-search">
        <div class="container">
           <div class="row">

          
             <div class="col-7 col-md-7 p-r-5">
               <input type="text" class="shadow-light form-control" formControlName="search"  placeholder="Search...">
               <button type="submit" class="btn btn-submit"><i class="fas fa-search"></i></button>
             </div>
             <div class="col-5 col-md-5 p-l-5">
              <select class="form-control"  formControlName="link"  (change)="changeSuit($event)"  >
                <option value="">Google</option>
                <option value="https://in.search.yahoo.com/search?p=">Yahoo IN</option>
                <option value="https://www.youtube.com/results?search_query=">Youtube</option>
                <option value="https://www.bing.com/search?q=">Bing</option>
                <option value="https://www.google.com/maps?&q="> Google Map</option>
                <option value="https://en.wikipedia.org/w/index.php?search=" >Wikipedia</option>
            </select>
            




                <!-- <div class="col-5 col-md-5 dropdown-custom-maindiv">
      <div class="dropdown-div-2">
        <ul class="dropdown-2">
        <li class="arrow-down">
        <a>Demo<i class="fa fa-angle-down red-text"></i></a>
            <ul>
                <li>
                <a href="#" class="red-text bg-color">wikipedia <img src="../assets/google.png"></a>
                   
              </li>  
                <li>
                  <a href="#" class="red-text bg-color" >Thesaurus <img src="../assets/Youtube.png"></a>
                  </li> 
                  <li>
                    <a href="#" class="red-text bg-color">Demo <img src="../assets/person.png"></a>
                    </li> 
               
            </ul>
           
        </li>
            </ul>
        </div>
        </div> -->
             </div>
         
          </div>
        </div>
      </div>
    </form>
  </div>  
</section> 
   <!--categories page start  -->
 <section>  
    <div class="categories">
        <div class="container-fluid">
          <div class="container">
        <h4 class="page-subtitle text-left">Top-Categories </h4>
        <div class="row">
          <div class="col-lg-3 col-md-3 col-sm-3 col-3" *ngFor="let item of products;">
            <!-- <li><button><a routerLink={{item.id}}>{{item.category_name}}{{item.id}}</a></button></li> -->
            <div class="icon-60 text-default" > 
              <a routerLink="/category/{{item.id}}">
                <img src="{{item.category_image}}" alt="" class="border-radius"></a>
              <div class="small text-mute"> <a routerLink="/category" ></a>{{item.category_name}} </div>
              
            </div>
          </div>
        </div>
      </div>
        </div>
        <div> 
          <a routerLink="/category/5" class="button-category">View all</a>
        </div>
       
    </div>
  </section>  

<!---top category slider start  -->
<div class="hot-topics Trending-Topics">
    <div class="container mb-4">
      <h4 class="page-subtitle" style="margin-bottom: 0px;">Trending Topics</h4>
     <!-- <a href="#" class="btn-sm float-right px-0">View all</a> -->
      <div class="swiper-container swiper-offers">
        <div class="scrollmenu">
        <div class="swiper-wrapper" >
          <div class="swiper-slide w-auto "  *ngFor="let topicItem of topic;let i= index">
            <a href ="{{topicItem.news_url}}" target="_blank"> 
               <div class="card w-250 h-130 position-relative overflow-hidden text-white border-0"  [style.background-color]="topicItem.overlay_color">
                <a href = "{{topicItem.news_url}}" target="_blank">
                <div class="background opacity-60">
                 <img src="{{topicItem.img_path}}" alt="" />
                 </div>
                 </a>
              <div class="card-body text-center z-1 h-50"></div>
              <div class="card-footer border-0 z-1">
                <div class="media">
                  <div class="media-body" style="white-space: normal;">
                    <span> {{topicItem.title}}</span>
                  </div>
                </div>
              </div>
            </div> </a>

          </div>

      <a routerLink="/trendpage" class="viewall-home" style="color:#f44336;margin-top:50px;"><i class="fa fa-arrow-circle-right fa-2x" aria-hidden="true"></i><br>View all</a>
         </div>
      </div>
    </div>
  </div>
</div> 
<!---top category slider end  -->    




<!--  trending shop start -->

<div class="hot-topics trending-website">
  <div class="container">
    <!-- <a routerLink="/store" class="button-viewall">View all</a> -->
    <h4 class="page-subtitle" style="margin-bottom: 15px; padding-top: 0px;">Trending Websites</h4>
    <div class="row">
      <div class="col-4 col-md-3-custom" *ngFor="let websitesItem of websiteLists; let i=index" >
        <div class="card border-0 shadow-light text-center mb-4"  *ngIf="i<12">
          <div class="card-body position-relative">
            <div class="h-100px position-relative overflow-hidden">
             <a routerLink="/store/{{websitesItem.id}}" > <div class="background background-h-100" style="background-image:url({{websitesItem.icon}});"> </div> </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- trending shop end-->




<!-- trending video start -->
<div class="page-content page-container tending-video-sec" id="page-content">
    <div class="">
        <div class="">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="">
                    <div class="">
                        <h4 class="card-title">Trending Video</h4>
                       
                        <div class="scrollmenu">
                          <div class="swiper-wrapper">
                            <div class="swiper-slide w-auto-1 " *ngFor="let itemVideo of videoLists;  let i=index" >
                              <a routerLink="/category"  *ngIf="i<3">  
                                <div class="card w-250 h-130 position-relative overflow-hidden ">
                                <div class=""> 
                                  <div class="item iframe-container">
                                  
                                  <iframe width="560" height="315" [src]="sanitizer.bypassSecurityTrustResourceUrl(itemVideo.video_id)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> 
                                    </div>
                                </div>
                                
                              </div> </a>
                              <div class="youtube-text-home">
                                {{itemVideo.title}}
                              </div>
                  
                            </div> 
                            <a routerLink="/video" class="viewall-home" style="color:#f44336;margin-top:35px;"><i class="fa fa-arrow-circle-right fa-2x" aria-hidden="true"></i><br>View all</a> 
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
</div>
<!-- trending video end -->



<!-- latest trend hashtag start -->
 <section>
 <div class="latest-news">
  <div class="container">
    <h4 class="page-subtitle">Trending Hashtag</h4>
       
      <div class="container">
        
         <div class="scrollmenu " >
        
      
         <span *ngFor="let itemhashtag of hashtagList; let i = index;">
 
         <button class="hashtag hashtag-social-div" (click)="ToggleButton(itemhashtag.id,itemhashtag.tag)"><i class="fas fa-hashtag farmer-1" ></i>{{itemhashtag.tag}}
        </button>
        <span id="pop_1"></span>
        <div class="row test showdiv test_hashtag" *ngIf="showSelected" style="margin-left:6px;" id="test_hashtag">          
          <a 
           href="{{insta}}" target="_blank"> <i class="fa fa-instagram"></i></a> 
          <a  href="{{twt}}"   target="_blank"> <i class="fa fa-twitter"></i></a>  
          <a  href="{{lkn}}"   target="_blank"> <i class="fa fa-linkedin"></i></a>  
          <a  href="{{fab}}"   target="_blank"> <i class="fa fa-facebook"></i></a> 
          <a  href="{{yub}}"   target="_blank"> <i class="fa fa-youtube-play" aria-hidden="true"></i></a> 
                
  
      </div>
        </span>
       
     
         </div>
     </div>     
</div> 
  </div>
  </section>   
  
  <!-- latest trend hashtag end -->


  <!-- trending deals and offer start for now-->

  <section class="deal-offer"> 
    
     <div class="container">
       <h4 class="page-subtitle">Deals and Offers</h4>
        <div class="container">
   <div class="row">
    <div class="tab-content" id="myTabContent" style=" margin-bottom: 50px;">
      <div class="tab-pane fade was-validated active show" id="details" role="tabpanel" aria-labelledby="details-tab">
         <div class="hot-topics trending-website">
             <div class="row">
                   <div class="col-6 col-md-3"  *ngFor="let dealitem of deals;let i= index">
           <div class="card border-0 shadow-light text-center mb-4 card-test">
             <div class="card-body position-relative">
               <div class="white-bg">
                 <a href = "{{dealitem.page_url}}" target="_blank">
                    <img src="{{dealitem.image}}" alt="" class="img-deals">
                  </a>
                    <div class="white-bg-txt">
                       <span class="excerpt">
                        {{dealitem.title | customPipe:0:30}}
                       </span>
                       <div class="text-center mt-3">
                          <div class="edit-icon text-right float-right">
                              <div class="container popup popup-custom">
                                <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='#' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                                </div></div>
                                <div class="card-footer">
                                <div class="separator clear-left">
                          <img src="{{dealitem.logo}}" alt="" class="w-100 custom-card-footer">
                       </div></div>
                    </div></div>
               </div>
             </div>
           </div>
         </div>
           
 <!-- <div class="col-6 col-md-3">
          <div class="card border-0 shadow-light text-center mb-4 card-test">
            <div class="card-body position-relative">
              <div class="white-bg">
                   <img src="../assets/images/mobile-hd.png" alt="" />
                   <div class="white-bg-txt">
                    <span class="excerpt">
                      RealMe1(4+64GB)
                      <span>Starting at &#8377; 10,990</span>
                    </span>
                      <div class="text-center mt-3">
                         <div class="edit-icon text-right float-right">
                             <div class="container popup popup-custom">
                              <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                               </div></div>
                               <div class="card-footer" >
                                 <div class="separator clear-left">
                         <img src="../assets/images/amazon-deal.png" alt="" class="w-100" />
                      </div></div></div>
                   </div>
              </div>
            </div>
          </div>
        </div>  -->
        <!-- <div class="col-6 col-md-3">
          <div class="card border-0 shadow-light text-center mb-4 card-test">
            <div class="card-body position-relative">
              <div class="white-bg">
                   <img src="../assets/images/mobile-hd.png" alt="" />
                   <div class="white-bg-txt">
                    <span class="excerpt">
                      RealMe1(4+64GB)
                      <span>Starting at &#8377; 10,990</span>
                    </span>
                      <div class="text-center mt-3">
                         <div class="edit-icon text-right float-right">
                             <div class="container popup popup-custom">
                              <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                               </div></div>
                               <div class="card-footer" >
                                 <div class="separator clear-left">
                         <img src="../assets/images/amazon-deal.png" alt="" class="w-100" />
                      </div></div></div>
                   </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div class="col-6 col-md-3">
          <div class="card border-0 shadow-light text-center mb-4 card-test">
            <div class="card-body position-relative">
              <div class="white-bg">
                   <img src="../assets/images/mobile-hd.png" alt="" />
                   <div class="white-bg-txt">
                    <span class="excerpt">
                      RealMe1(4+64GB)
                      <span>Starting at &#8377; 10,990</span>
                    </span>
                      <div class="text-center mt-3">
                         <div class="edit-icon text-right float-right">
                             <div class="container popup popup-custom">
                              <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                               </div></div>
                               <div class="card-footer" >
                                 <div class="separator clear-left">
                         <img src="../assets/images/amazon-deal.png" alt="" class="w-50" />
                      </div></div></div>
                   </div>
              </div>
            </div>
          </div>
        </div>  -->
   
   </div>
    </div>
    </div>
    </div>

    

   </div>

  </div> 
 
</div>
</section> 

<!-- Deals and offer end  -->


  <!-- deals and offer and news section start in future  -->

  <!-- Tab links -->

 <!-- <div class="tab" >
  <button class="tablinks" onclick="openCity(event, 'Deals & Offers')">Deals & Offers</button>
   <button class="tablinks" onclick="openCity(event, 'News')">News</button>
</div>  -->

<!-- Tab content -->

<!-- Deals tab start -->

 <!-- <div id="Deals & Offers" class="tabcontent"> -->
  <!-- <h4>Deals & Offers</h4> -->
  <!-- <div class="row">
   
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade was-validated active show" id="details" role="tabpanel"             aria-labelledby="details-tab">
           <div class="hot-topics trending-website">
               <div class="row">
                     <div class="col-6 col-md-3">
              <div class="card border-0 shadow-light text-center mb-4">
       <div class="card-body position-relative">
               <div class="badge badge-success float-right mt-3">30% off</div>
       <div class="white-bg catimg">
             <img src="../assets/mobile-hd.jpg" alt="" />
            <div class="white-bg-txt">
               <h4>
                 RealMe1(4+64GB)
               </h4>
             </div>
       </div> 
       
       <div class="card-footer">
             <div class="separator clear-left">
                  <p class="btn-add">
                         <span class="price">&#8377; 10,990</span>
                        <img src="../assets/amazon-deal.png" alt="" class="w-100" />
                  </p>
              </div>
      </div>

    </div>
   
   </div> 
   </div>
           
   
           <div class="col-6 col-md-3">
              <div class="card border-0 shadow-light text-center mb-4">
     <div class="card-body position-relative">
        <div class="card-img-overlay d-flex justify-content-start">
         
           </div>
       
       <div class="badge badge-success float-right mt-3">10% off</div>
       <div class="white-bg catimg">
            <img src="../assets/mobile-hd.jpg" alt="" />
            <div class="white-bg-txt">
               <h4>
                 RealMe1(4+64GB)
                
               </h4>
               <div class="text-center mt-3">
                
               </div>
            </div>
       </div> 
       
      <div class="card-footer"  style="margin-top: 15px">
          <div class="separator clear-left">
               
                 <p class="btn-add">
                    <span class="price">&#8377; 10,990</span>
   
                    <img src="../assets/amazon-deal.png" alt="" class="w-100" />
                   
                   
                   </p>
                  
             </div>
       </div>
     </div>
   
   </div> 
   </div>
   
     <div class="col-6 col-md-3">
              <div class="card border-0 shadow-light text-center mb-4">
      <div class="card-body position-relative">
        <div class="card-img-overlay d-flex justify-content-start">
          
        </div>
    
       <div class="badge badge-success float-right mt-3">10% off</div>
       <div class="white-bg catimg">
            <img src="../assets/mobile-hd.jpg" alt="" />
            <div class="white-bg-txt">
               <h4>
                 RealMe1(4+64GB)
                
               </h4>
               <div class="text-center mt-3">
               
               </div>
            </div>
       </div> 
       
      <div class="card-footer"  style="margin-top: 15px">
          <div class="separator clear-left">
               
                 <p class="btn-add">
                    <span class="price">&#8377; 10,990</span>
                   
                    <img src="../assets/amazon-deal.png" alt="" class="w-100" />
                   </p>
                              </div>
       </div>
     </div>
   
   </div> 
   </div>
   
   
   <div class="col-6 col-md-3">
              <div class="card border-0 shadow-light text-center mb-4">
     <div class="card-body position-relative">
        <div class="card-img-overlay d-flex justify-content-start">
           
           </div>
     
       <div class="badge badge-success float-right mt-3">10% off</div>
       <div class="white-bg catimg">
            <img src="../assets/mobile-hd.jpg" alt="" />
            <div class="white-bg-txt">
               <h4>
                 RealMe1(4+64GB)
                
               </h4>
               <div class="text-center mt-3">
                 
               </div>
            </div>
       </div> 
       
      <div class="card-footer"  style="margin-top: 15px">
          <div class="separator clear-left">
               
                 <p class="btn-add">
                    <span class="price">&#8377; 10,990</span>
                   
                    <img src="../assets/amazon-deal.png" alt="" class="w-100" />
                   </p>
                 
             </div>
       </div>
     </div>
   
   </div> 
   </div>
   <div class="col-6 col-md-3">
              <div class="card border-0 shadow-light text-center mb-4">
     <div class="card-body position-relative">
        <div class="card-img-overlay d-flex justify-content-start">
         </div>
      
       <div class="badge badge-success float-right mt-3">10% off</div>
       <div class="white-bg catimg">
            <img src="../assets/mobile-hd.jpg" alt="" />
            <div class="white-bg-txt">
               <h4>
                 RealMe1(4+64GB)
                
               </h4>
               <div class="text-center mt-3">
               </div>
            </div>
       </div> 
       
      <div class="card-footer"  style="margin-top: 15px">
          <div class="separator clear-left">
                  <p class="btn-add">
                    <span class="price">&#8377; 10,990</span>
                    <img src="../assets/amazon-deal.png" alt="" class="w-100" />
                  </p>
            </div>
        </div>
     </div>
   
   </div> 
   </div><div class="col-6 col-md-3">
     
     <div class="card border-0 shadow-light text-center mb-4">
     <div class="card-body position-relative">
        <div class="card-img-overlay d-flex justify-content-start">
             <a href="#" class="card-link text-danger like">
               <i class="fas fa-heart"></i>
             </a>
           </div>
       
       <div class="badge badge-success float-right mt-3">10% off</div>
       <div class="white-bg catimg">
            <img src="../assets/mobile-hd.jpg" alt="" />
            <div class="white-bg-txt">
               <h4>
                 RealMe1(4+64GB)
                
               </h4>
               <div class="text-center mt-3">
               </div>
            </div>
       </div> 
       
      <div class="card-footer"  style="margin-top: 15px">
          <div class="separator clear-left">
               
                 <p class="btn-add">
                    <span class="price">&#8377; 10,990</span>
                   
                    <img src="../assets/amazon-deal.png" alt="" class="w-100" />
   
                   
                  </p>
                
            </div>
       </div>
     </div>
   
   </div> 
   </div>
          <div class="col-6 col-md-3">
              <div class="card border-0 shadow-light text-center mb-4">
                </div> 
          </div>
   </div>
    </div>
    </div>
    </div>

    

     

  </div> 

  
</div> -->

<!-- deals tab end -->

<!-- news tab start -->

<!--<div id="News" class="tabcontent">
   <h4>News</h4> 
   <div class="row">
      <div class="col-12 col-sm-12">
        <div class="card mb-4 shadow-light border-0">
          <div class="card-img w-25"> <img src="../assets/food2.jpg" alt=""  class="rounded-left"> </div>
          <div class="card-text w-75"> 
             <span class="badge badge-pill badge-primary mb-2 mr-2">Politics</span>
             <span class="badge badge-pill badge-success mb-2">politics</span>  
             <a href="#" class="float-right px-0">Date:08.03.2021 </a>
            <h4>Lorem Ipsum is simply dummy text of the printing.</h4> 
           <div class="float-left">
              <img src="images/india-today.jpg" alt="" />
            </div>
            <div class="float-right">
              <a class="read-more" href="#">Read More</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12">
        <div class="card mb-4 shadow-light border-0">
          <div class="card-img w-25"> <img src="../assets/food2.jpg" alt=""  class="rounded-left"> </div>
          <div class="card-text w-75"> 
             <span class="badge badge-pill badge-primary mb-2 mr-2">Politics</span>
             <span class="badge badge-pill badge-success mb-2">politics</span>  
             <a href="#" class="float-right px-0">Date:08.03.2021 </a>
            <h4>Lorem Ipsum is simply dummy text of the printing.</h4> 
            <div class="float-left">
              <img src="images/india-today.jpg" alt="">
            </div>
            <div class="float-right">
              <a class="read-more" href="#">Read More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  
</div>  -->

<!-- news tab end --> 

<!-- deals and offer section and news section end -->




<!-- sticky footer tabs -->
<section id="foot">
  <div class="footer-tabs border-top text-center">
    <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" routerLink="/"><i class="fas fa-home"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/offers/"><i class="fas fa-handshake"></i>
        </a>
      </li>
      <li class="nav-item centerlarge">
      
        <a class="nav-link bg-default"  *ngIf="user_id" routerLink="/favourite"
          ><i class="fa fa-star"></i>
        </a>
        <a class="nav-link bg-default" *ngIf="!user_id" routerLink="/login"
          ><i class="fa fa-star"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/"><i class="fas fa-bell"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/store"><i class="fas fa-globe"></i>
        </a>
      </li>
      
    </ul>
  </div>
</section>

<!-- sticky footer tabs ends --> 

<!-- scroll to top button -->
<button type="button" class="btn btn-default scrollup position-fixed btn-44" style="background-color: aqua;"> <i class="fas fa-chevron-up"></i> </button>
<!-- scroll to top button ends--> 
