import { Component, OnInit } from '@angular/core';
import { UserService } from './../user.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  currentUser: string[] | any = []
  videoList:string[]|any=[]
  videoLists:string[]| any = []
  user_id:any

  constructor(private router: Router,private route: ActivatedRoute, private userService:UserService,  private auth: AuthenticationService,   public sanitizer: DomSanitizer) 
  {

      /**
     * Youtube Video List
     */
       this.videoList = this.userService.youtubeList()
       .subscribe(videoLists => {
        // console.log(videoLists);
         this.videoLists = videoLists;
        
       });
   }

  ngOnInit(): void {
    this.user_id = window.sessionStorage.getItem('id');
    this.currentUser = this.auth.getAllData();
    //console.log(this.currentUser);
    this.currentUser;
  }

  logout() {
    this.auth.destroyToken();
    this.router.navigate(['/login']);
  }

}
