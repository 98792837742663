import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from './../user.service';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {
  storeDetails: any;
  storeList: any;
  id: any;
  storeSubCategory: any;
  subCategory: any;
  user_id: any;
  currentUser: any;
  items: any;
  key: any;
  urlBySubTitle: any;
  urlVal: any;
  urlValue: any;
  storeId: any;
  storeLogo: any;
  storeUrl: any;
  getStoreLists: any;
  getAllDeals: any;
  storeCategory: any;
  store_id: any;
  oppoSuitsForm: any;
  selected: any;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private auth: AuthenticationService,
    private router: Router
  ) {
    const store_id = this.route.snapshot.params.id;
    this.selected = store_id;
    this.storeSubCategory = this.userService
      .getDealByStoreId(store_id)
      .subscribe(subCategory => {
        // console.log("hstot",storeList);
        this.subCategory = subCategory;
      });
  }

  ngOnInit(): void {
    this.user_id = window.sessionStorage.getItem('id');
    this.currentUser = this.auth.getAllData();
    const store_id = this.route.snapshot.params.id;
    this.selected = store_id;
    this.storeDetails = this.userService
      .getDealByStoreId(store_id)
      .subscribe(storeList => {
     //   console.log('hstot', storeList);
        this.storeList = storeList;
        this.storeLogo = storeList.storeLogo;
        this.storeUrl = storeList.storeUrl;
        this.getStoreLists = storeList.getStoreLists;
        this.getAllDeals = storeList.getAllDeals;
        this.storeCategory = storeList.storeCategory;
        this.store_id = storeList.store_id;
      });

    const storeId = this.route.snapshot.params.id;
    this.selected = storeId;
    this.storeSubCategory = this.userService
      .getDealByStoreId(storeId)
      .subscribe(subCategory => {
        // console.log("hstot",storeList);
        this.subCategory = subCategory;
      });
  }

  getStore(e: any) {
  //  alert(e.target.value);
    console.log('storeId', e.target.value);
    const value = e.target.value;
    this.selected = value;
    this.storeSubCategory = this.userService
      .getDealByStoreId(e.target.value)
      .subscribe(subCategory => {
        //console.log('hstot', subCategory);
        this.storeList = subCategory;
        this.storeLogo = subCategory.storeLogo;
        this.storeUrl = subCategory.storeUrl;
        this.getStoreLists = subCategory.getStoreLists;
        this.getAllDeals = subCategory.getAllDeals;
        this.storeCategory = subCategory.storeCategory;
        this.store_id = subCategory.store_id;
        // this.categoryId = this.route.snapshot.params.id

      //  this.router.onSameUrlNavigation = 'reload';
      });
  }

  logout() {
    this.auth.destroyToken();
    this.router.navigate(['/login']);
  }
  getSubcategory(item: any) {
    // const val= item.value.slice("_").pop()
    // console.log('sdsdfd', val);
    this.items = item.value;

    // console.log("yytttttttttttttttttttttttttttttttt",this.items)
  }

  getURL(subTitle: string, id: string) {
    // const storeId = this.route.snapshot.params.id;
    this.urlBySubTitle = this.userService
      .getUrlBySubTitle(subTitle, id)
      .subscribe(urlVal => {
        window.open(urlVal.url);
        // alert(urlVal.url)
      });
  }
}
