<section class="category-page">
    <header class="topheader">
        <nav class="navbar">
            
          <span style="font-size:30px;cursor:pointer;color: #f44336" onclick="openNav()">&#9776; </span>
          <div class="logo"> <a href="#"><img src="../assets/logo-home.png" style="margin-left: -10%;"></a></div>
             
              <div class="header-search">
                <div class="search-main dropdown"> 
                    <!-- <a href="javaScript:void(0)" class="toggle"><i class="fas fa-search"></i></a> -->
                  <div class="dropdown-menu top-search">
                     <!-- <input type="text" id="somenth" class="form-control" placeholder="Search.." /> -->
                    <!-- <button type="submit" class="btn btn-submit"><i class="fas fa-search"></i></button> -->
                  </div>
                </div>
                <!-- <a href="#" class=""> <span class="avatar avatar-30"><img src="../assets/user5.jpg" alt=""></span> </a>--> </div>  
        </nav>
      </header>
      <!-- Fixed navbar ends -->
    
    <!-- side navbar start -->
      <div id="mySidenav" class="sidenav">
        <div class="side-menu-wrap">        
          <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
          <div class="row no-gutters mb-4">
            <div class="col-auto align-self-center ml-3">
              <figure class="avatar avatar-50"> <img src="../assets/images/user5.png" alt=""> </figure>
            </div>
            <div class="col pl-3 align-self-center">
              <p class="my-0" style="color: black;">Hi there!</p>
            </div>
            <div class="col-auto align-self-center"> <a routerLink="/signup" class="btn btn-link text-white p-2"><i class="fas fa-power-off"></i></a> </div>
          </div>
          <a routerLink="/" class="active"><i class="fas fa-home"></i>Home</a>
          <a href="#"><i class="fas fa-star"></i>Rate Us</a> 
          <!-- <a href="about-us.html"><i class="fas fa-address-card"></i>About Us</a>  -->
          <!-- <a href="contact.html"><i class="fas fa-address-book"></i>Contact Us</a>  -->
          <a href="#"><i class="fas fa-share-alt"></i>Share</a> 
          <a routerLink="/register"><i class="fas fa-sign-in-alt"></i>Sign up</a> 
          <a routerLink="/signup"><i class="fas fa-user"></i>Log In</a> 
          <a routerLink="/profile"><i class="fas fa-user-circle"></i>Profile</a> 
        </div>
    </div>
</section> 
   
    <!-- side navbar end -->      


<!-- video section start -->
<section>
    <div class="latest-video">
        <!-- <div class="carb"></div> -->
        <div class="container">
          
           <h6 class="page-subtitle video">Trending Video</h6><br> 
           <div class="scrollmenu">
           <button class="hashtag "><img src="../assets/images/accessories.jpg" class="hash-butn">Farmer</button>
           <button class="hashtag "><img src="../assets/images/accessories.jpg" class="hash-butn">chief justice of india</button>
           <button class="hashtag "><img src="../assets/images/accessories.jpg" class="hash-butn">Politics</button>
           <button class="hashtag "><img src="../assets/images/test.png" class="hash-butn">Farmer</button>
           <button class="hashtag "><img src="../assets/images/test.png" class="hash-butn">chief justice of india</button>
           <button class="hashtag "><img src="../assets/images/test.png" class="hash-butn">Politics</button>
         </div>
         </div>
   </div>

   <div class="row-custom-1">
    <div class="column-custom-1 iframe-container">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/xR1KdCsPTOs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <span class="youtube-text">
       MEERA MITHUN BOYFRIEND ATROCITIES TROLL - TODAY TRENDING
    </span> 
     <!-- <div class="edit-icon text-right float-right">
        <div class="container popup-1" >
            <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
          </div></div> -->
    <div class="column-custom-1">
        <img src="https://i.ytimg.com/vi/B_aElhkIxcc/mqdefault.jpg" alt="video-category" class="video-custom">
      </div>
      <span class="youtube-text">MEERA MITHUN TODAY TRENDING TODAY MEERA MITHUN TODAY TRENDING TODAY 
      </span>
      <!-- <div class="edit-icon text-right float-right">
        <div class="container popup-1" >
            <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
          </div></div> -->
       

     
          
  <!-- sticky footer tabs -->
  <section id="foot">
    <div class="footer-tabs border-top text-center">
      <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" routerLink="/"><i class="fas fa-home"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/offers"><i class="fas fa-handshake"></i>
          </a>
        </li>
        <li class="nav-item centerlarge">
          <a class="nav-link bg-default" routerLink="/favourite"><i class="fa fa-star"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/"><i class="fas fa-bell"></i>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/store"><i class="fas fa-globe"></i>
          </a>
        </li>
        
      </ul>
    </div>
  </section>
    
    <!-- sticky footer tabs ends -->           



























 