<!-- forget Page -->
<section class="banner-hero vh-100 scroll-y bg-dark">
    <div class="carb-bg-left"></div>
    <div class="background-opac">
      <div class="container h-100 text-white">
        <form [formGroup] = 'forgotPasswordForm' (ngSubmit)= 'resetPassword()'>
               <div class="row h-100 h-sm-auto">
                   <div class="col-12 col-md-8 col-lg-5 col-xl-4 mx-auto align-self-center text-center ncard">
                       <div class="logo"> <a href="#"><img src="../assets/logo-home.png"></a> </div>
                       <h5 style="margin-top:15px;font-size: 25px;">Sorry for being here</h5>
                       <h3 style="margin-top:15px;font-size: 20px;">Reset Password</h3>
                       <p style="font-size:15px;">In order to reset password , provide your registered email address</p>
                       <h3 style="margin-top:15px;font-size: 20px;" *ngIf ="message">{{message}}</h3>
                       <div class="form-group">
                        <label for="inputEmail" class="sr-only">Email address</label>
                        <input type="email" formControlName='user_email'id="inputEmail" class="form-control form-control-lg" placeholder="Email address" required="" autofocus="">
                        <div *ngIf="forgotPasswordForm.get('user_email').hasError('required') && forgotPasswordForm.get('user_email').touched" style="color: #ffff;">Email Is Required</div>
                     </div>
                       <!-- <div class="form-group">
                           <label for="email" class="sr-only">Password</label>
                           <input type="password" id="email" class="form-control form-control-lg border-0" placeholder="Email" required="">
                       </div> -->
                       
                         <div class="mb-4">
                            <button type="submit" class="btn btn-lg btn-default default-shadow btn-block" click='resetPassword()'>Submit</button>
                           <!-- <a href="#" click='resetPassword()'class=" btn btn-lg btn-default default-shadow btn-block">Reset Password <span class="ml-2 icon arrow_right"></span></a> -->
                       </div>
                   </div>
               </div>
               </form>
           </div>
    </div>
 </section>
<!--forget Page --> 

