import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';
import { UserService } from './../user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { pipe } from 'rxjs';



@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  [x: string]: any;
  currentUser: string[] | any = []
  profileDeatilById:any
  profileById:any
  submitted: boolean|any
  profileForm :FormGroup|any
  user_id :any
  image:any
  res:any
  countryname:any
 
  constructor(private auth: AuthenticationService,private route: Router,private fb:FormBuilder,private userService: UserService,) {
  
  }
  get f(){
    return this.profileForm.controls;
  }
  ngOnInit(): void {
 
    this.profileForm = this.fb.group({
      user_id :[null,[Validators.required]],
      user_name :[null,[Validators.required,Validators.minLength(4)]],
      user_email :[null ,[Validators.required,Validators.pattern("^([a-zA-Z0-9.-]+)@([a-zA-Z]{5,12}).([a-zA-Z.]{2,20})$")]],
      user_image: ['', [Validators.required]],
      user_phone: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country: ['', [Validators.required]]
     
  });
 // console.log("hghghgh",this.profileForm)
    const user_id = window.sessionStorage.getItem('id');
    this.user_id = user_id
    //console.log("gfgf",user_id);
    this.profileDeatilById= this.userService.profileDetails(user_id).subscribe(profileById=>{
       //console.log("profile",profileById.userData);
         this.profileById= profileById.userData
         this.country = profileById.country
        
        
       //  console.log("profile",this.profileById);
        });
  } 

  SelectImage(event:any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const file =  event.srcElement.files[0];
      //this.user_image = file;
      this.profileForm.get('user_image').setValue(file);
      }
      //const file = event.target.files[0].name;
     // this.user_image = file;
     // console.log("profile", this.user_image);
    
  }

    updateProfile():void{
     
   //   console.log("profile",this.profileForm.get('user_image').value);

      let formObj = this.profileForm.getRawValue();
     console.log(formObj);
    this.userService.updateProfile(formObj).subscribe(res => {
    console.log("sdgggggggg",res)
    this.res = res.message
    this.router.onSameUrlNavigation = 'reload';
    })

    }

   


  

}
function f() {
  throw new Error('Function not implemented.');
}

