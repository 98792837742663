<!--signup Page -->
  <!-- <section class="banner-hero vh-100 scroll-y bg-dark"> -->
    <div class="background-opac bg-dark">
        <div class="carb-bg-left"></div>
           
        <div class="container h-100 text-white">
                <div class="row h-100 h-sm-100">
                    <div class="col-12 col-md-8 col-lg-5 col-xl-4 mx-auto align-self-center text-center ncard">
                        <div class="logo"> <a href="#"><img src="../assets/logo-home.png"></a> </div>
                        
                        <h5 style="font-size: 30px; margin-top: 15px;">Welcome back,</h5>
                        <h3 style="font-size: 15px; margin:5px 0px;" >Please Sign In</h3>
                        <h3 class="err-message" style="font-size: 20px" *ngIf = "message">{{message}}</h3>
                        <form [formGroup] = "loginForm" (ngSubmit)= 'loginUser()'>
                        <div class="form-group">
                            <label for="inputEmail" class="sr-only">Email address</label>
                            <input type="email"  formControlName='user_email' id="inputEmail" class="form-control form-control-lg border-0" placeholder="Email address" required="" autofocus="">
                        </div>
                        <div class="form-group">
                            <label for="inputPassword" class="sr-only">Password</label>
                            <input type="password" formControlName='password'id="inputPassword" class="form-control form-control-lg border-0" placeholder="Password" required="">
                        </div>
                        <div class="form-group">
                           <button type="submit" style="width:80%; margin-left:10px; margin-top:0px;" >Login</button>
                       </div>
                    </form>
                        <div class="my-3 row">
                            <div class="col-6 col-md py-1 text-left">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customCheck1" checked="">
                                    <label class="custom-control-label" for="customCheck1">Remember Me</label>
                                </div>
                            </div>
                            <div class="col-6 col-md py-1 text-right text-md-right">
                                <a routerLink="/forget-password" class="text-white">Forgot Password?</a>
                            </div>
                        </div>
                        <!-- <div class="mb-4">
                            <a href="/signup" class=" btn btn-lg btn-default default-shadow btn-block">Sign In <span class="ml-2 icon arrow_right"></span></a>
                        </div>  -->
                      <div class="mb-4">
                            <p>Do not have account yet?<br>Please <a routerLink="/register" class="login-txt">Sign up</a> here.</p>
                            <div>
                                <h5 style="font-size:18px; text-align: center;color: #fff; margin-top:15px;">Sign Up with Social-Media</h5>
                                <div>
                                    <a href="#" ><img src="../assets/google.jpg" class="icon-1"></a>
                                    <a href="#"><img src="../assets/facebook.png" class="icon-1"></a>
                                 
                                </div>
                              </div>
                             
                    </div>
                </div>
            </div>
     </div>
 <!-- signup Page --> 
