// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl :"https://trendingbk.neatoz.com/api/v1/",
  apiServerCategory: "https://trendingbk.neatoz.com/api/v1/subCategoryList",
  apiServerTopic: "https://trendingbk.neatoz.com/api/v1/getTopicById",
  apiServerWebsites: "https://trendingbk.neatoz.com/api/v1/trendingWebsiteList",
  apiServerStore:"https://trendingbk.neatoz.com/api/v1/getDealsByStoreId",
  apiServerLink:"https://trendingbk.neatoz.com/api/v1/getLinkBySubCategory",
  apiServerProfile:"https://trendingbk.neatoz.com/api/v1/getUserProfile",
  apiURL :"https://trendingbk.neatoz.com/api/v1/getUrlFromSubcategory",
  apiDeals :"https://trendingbk.neatoz.com/api/v1/getSubcategoryById",
  apiSearchStore:"https://trendingbk.neatoz.com/api/v1/searchStore",
 // apiServerFavourite:"http://localhost:1234/neatozapp-backend/api/v1/user-favourite-list",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
