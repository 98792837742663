import { Favourite } from './favourite';
import { Deals } from './deals';
import { Platform } from './platform';
import { HashTag } from './hashTag';
import { SubCategory } from './subCategory';
import { Topic } from './topic';
import { Category } from './category';
import { User } from './user.model';
import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient , HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import {Store}from './store';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  
  constructor(private http :HttpClient, private router: Router) { }
 
  apiServer    = environment.apiServerCategory;
  apiTopic     = environment.apiServerTopic;
  apiWebsites  = environment.apiServerWebsites;
  apiStore     = environment.apiServerStore;
  apiLink      = environment.apiServerLink;
  apiProfile   = environment.apiServerProfile;
  apiURL        = environment.apiURL;
  apiDeal     = environment.apiDeals;
  apiSearchStore = environment.apiSearchStore;
  
  RegisterUser(post:User):Observable<User>{
   // console.log("response",post);
    return this.http.post<User>(environment.baseUrl+'register' , post);
    
   }

   LoginUserInfo(post:User):Observable<User>{
    //console.log("response",post);
    return this.http.post<User>(environment.baseUrl+'login' ,post);

 }
 
  AddUserFav(post:Favourite):Observable<Favourite>{
  //console.log("response",post);
  return this.http.post<Favourite>(environment.baseUrl+'add-user-fav' ,post);

}


CategoryList():Observable<any>{

  return this.http.get<Category>(environment.baseUrl+'categoryList');

}
/** Topic List */
TrendingTopicList():Observable<any>{

  return this.http.get<Topic>(environment.baseUrl+'topicList');

}

/** Subcategory List */
subCategoryList(id:any): Observable<any> {
 console.log("id",id);
  return this.http.get(`${this.apiServer}/${id}`)
}

/** Topic list by id */
topicById(id:any): Observable<any> {
 // console.log("id",id);
  return this.http.get(`${this.apiTopic}/${id}`)
}

/** Websites List */
TrendingWebsitesList():Observable<any>{
  return this.http.get(`${this.apiWebsites}`)
}

/*
Youtube List
*/
youtubeList():Observable<any>{
  return this.http.get(`${environment.baseUrl+'trendingVideo'}`)
}

/**Store list by id */
getDealByStoreId(id:any): Observable<any> {
  //console.log("id",id);
   return this.http.get(`${this.apiStore}/${id}`)
 }

 /**Store list by id */
getLinkBySubId(id:any): Observable<any> {
 // console.log("id",id);
   return this.http.get(`${this.apiLink}/${id}`)
 }

 /** HashTag List */
HashTagList():Observable<any>{

  return this.http.get<HashTag>(environment.baseUrl+'hashtag-list');

}
 /**profile details list by id */
    profileDetails(id:any): Observable<any> {
    //console.log("id",id);
    return this.http.get(`${this.apiProfile}/${id}`)
  }

  // updateProfileDetailsById(id:any, file:File, data: any): Observable<any> {
  //   let formData = new FormData();
  //   formData.append('file', file)
  //   formData.append('name', data.name)
  //   formData.append('phone', data.phone)
  //   formData.append('pincode', data.pincode)
  //   formData.append('city', data.city)
  //   formData.append('state', data.state)
   
  //   return this.http.put(`${this.serverLoginApi}/${id}`, formData)
  // }
  updateProfile (post:User):Observable<User>
  {  
      let  updateApi= this.http.post<User>(environment.baseUrl+'updateProfile' , post);
    console.log("xvdxdf",updateApi);
     return updateApi
     
      // return this.http.put(`${this.serverLoginApi}/${id}`, formData)
    }
    

     
     /**FavouriteDetails list by id */
  FavouriteDetails(id:any): Observable<any> {
  // console.log("id",id);
    return this.http.get<Favourite>(`${environment.baseUrl+'user-favourite-list'}/${id}`)
  }
    //  /**OverLay Color  */
    //  overlayColor(): Observable<any> {
    //     return this.http.get(environment.baseUrl+'overlay-color')
    //   }

       /** platformList List */
platformList():Observable<any>{

  return this.http.get<Platform>(environment.baseUrl+'platformList');

}

 /** Deals and offer  List */
 DealsAndOffer():Observable<any>{

  return this.http.get<Deals>(environment.baseUrl+'getAllDealandOffer');

}

 /** Deals and offer  List */
 getUrlBySubTitle(subTitle:any,storeId:any):Observable<any>{
  //console.log("subtitle",subTitle);
  //console.log("storeId",storeId)
   let params = new HttpParams()
   .set('subTitle', subTitle)
   .set('storeId', storeId);

console.log(params.toString());

let data = this.http.get<Deals[]>(this.apiURL , {params});
//console.log("urlp",data)
    return data;


}

//platform array for hashtag
getPlatformByHashtagId(id:any): Observable<any> 
{
  // console.log("id",id);
    return this.http.get<HashTag>(`${environment.baseUrl+'getPlatformByHashtagId'}/${id}`)
}

 /** Top category  */
 offer():Observable<any>{

  return this.http.get<Deals>(environment.baseUrl+'getOfferList');

}

/**
 * Get offer by Id
 */
getOfferById(id:any): Observable<any> 
{
  // console.log("id",id);
    return this.http.get<Deals>(`${this.apiDeal}/${id}`)
}
/**change password */
forgotPassword(post:User):Observable<User>{
 // console.log("response",post);
   return this.http.post<User>(environment.baseUrl+'change-password' , post);
  }

  searchStores(post:Store):Observable<Store>
  {
    console.log("response",post);
     return this.http.post<Store>(this.apiSearchStore , post);
    }

}

