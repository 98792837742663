import { Component, OnInit } from '@angular/core';
import { UserService } from './../user.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { Router } from '@angular/router';



@Component({
  selector: 'app-trendpage',
  templateUrl: './trendpage.component.html',
  styleUrls: ['./trendpage.component.css']
})
export class TrendpageComponent implements OnInit {
  topicSubscription:any
  currentUser: string[] | any = []
  topic:any
  user_id:any
  constructor(private router: Router,private route: ActivatedRoute, private userService:UserService,  private auth: AuthenticationService) { }

  ngOnInit(): void {
    this.user_id = window.sessionStorage.getItem('id');
    this.currentUser = this.auth.getAllData();
    //console.log(this.currentUser);
    this.currentUser;
    
      /**
     * Trending Topic List
     */
    this.topicSubscription = this.userService
    .TrendingTopicList()
    .subscribe(topic => {
      this.topic = topic;
    });
  }


 logout() {
    this.auth.destroyToken();
    this.router.navigate(['/register']);
  }


}
