<section class="category-page">
  <header class="topheader">
      <nav class="navbar">
          
        <span class="topbar-custom" onclick="openNav()">&#9776; </span>
        <div class="logo"> <a href="#"><img src="../assets/logo-home.png" style="margin-left: -10%;"></a></div>
           
            <div class="header-search">
              <div class="search-main dropdown"> 
                  <!-- <a href="javaScript:void(0)" class="toggle"><i class="fas fa-search"></i></a> -->
                <div class="dropdown-menu top-search">
                   <!-- <input type="text" id="somenth" class="form-control" placeholder="Search.." /> -->
                  <!-- <button type="submit" class="btn btn-submit"><i class="fas fa-search"></i></button> -->
                </div>
              </div>
              <!-- <a href="#" class=""> <span class="avatar avatar-30"><img src="../assets/user5.jpg" alt=""></span> </a>--> </div>  
      </nav>
    </header>
    <!-- Fixed navbar ends -->
  
 <!-- side navbar start -->
 <div id="mySidenav" class="sidenav">
  <div class="side-menu-wrap">        
    <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
    <div class="row no-gutters mb-4">
      <div class="col-auto align-self-center ml-3">
        <figure class="avatar avatar-50"> <img src="../assets/images/user5.png" alt=""> </figure>
      </div>
      <div class="col pl-3 align-self-center">
        <p class="my-0" style="color: black;"*ngIf="!user_id" >Hii There</p>
        <p class="my-0" style="color: black;"*ngIf="user_id" >{{currentUser}}</p>
      </div>
      <div class="col-auto align-self-center"> <a (click)='logout()'*ngIf="user_id" class="btn btn-link text-white p-2"><i class="fas fa-power-off"></i></a> </div>
    </div>
    <a routerLink="/" class="active"><i class="fas fa-home"></i>Home</a>
    <a href="#"><i class="fas fa-star"></i>Rate Us</a> 
    <!-- <a href="about-us.html"><i class="fas fa-address-card"></i>About Us</a>  -->
    <!-- <a href="contact.html"><i class="fas fa-address-book"></i>Contact Us</a>  -->
    <a href="#"><i class="fas fa-share-alt"></i>Share</a> 
    <a routerLink="/register"><i class="fas fa-sign-in-alt"></i>Sign up</a> 
    <a routerLink="/login" *ngIf="!user_id"><i class="fas fa-user"></i>Log In</a> 
    <a routerLink="/login" *ngIf="!user_id"><i class="fas fa-user-circle"></i>Profile</a> 
      <a routerLink="/profile" *ngIf="user_id"><i class="fas fa-user-circle"></i>Profile</a> 
  </div>

</div>


<!-- side navbar end -->


<!-- offers start -->
<section>
    <div class="latest-video">
        <!-- <div class="carb"></div> -->
        <div class="container">
          
           <h2 class="" style="text-align: center;">Offers</h2>
           <div class="scrollmenu">
           <button class="hashtag " *ngFor="let item of offerDetails; let i = index;" (click)="getSubCategory(item.category_id)"><img src="https://coupon.2il.org/public/images/category/{{item.pic}}" style="margin-right: 5px; width: 22px; height: 22px; border-radius: 30px;">{{item.name}}</button>
         </div>
         </div>
   </div>
   <div>
  
   </div>
    <div class="tab-content" id="myTabContent" style="padding: 15px;margin-bottom: 50px!important;">
        <div class="tab-pane fade was-validated active show" id="details" role="tabpanel" aria-labelledby="details-tab">
           <div class="hot-topics trending-website">
               <div class="row">
               
                <ng-container *ngFor="let subitem of SubCategoryList;"   >
                     <div class="col-6 col-md-3" >
             <div class="card border-0 shadow-light text-center mb-4 card-test" >
               <div class="card-body position-relative">
                 <a href = "{{subitem.page_url}}" target="_blank">
                 <div class="white-bg">
                      <img src="{{subitem.image}}" alt="" style="width: 128px; height: 155px;"/>
                      <div class="white-bg-txt">
                        <span class="excerpt" >

                         {{subitem.title | customPipe:0:30 }}
                        
                          <span>Starting at &#8377; 10,990</span>
                        </span>
                       
                         <div class="text-center mt-3">
                            <div class="edit-icon text-right float-right">
                                <div class="container popup popup-custom">
                                  <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                                  </div></div>
                                  <div class="card-footer" >
                                  <div class="separator clear-left">
                            <img src="{{subitem.logo}}" alt="" class="w-100" style="height:40px;width:40px" />
                         </div></div>
                      </div></div>
                 </div>
                 </a>
               </div>
             </div>
           </div>
          
           </ng-container>
           <div class="col-6 col-md-6" *ngIf="!SubCategoryList?.length" >
            <div class="card border-0 shadow-light text-center mb-4 card-test">
              <div class="card-body position-relative">
                <div class="white-bg">
                 <span style="text-align: center;">Currently, no deals available. Come back later to catch up</span> 
                </div >
              </div>
          </div>
           </div>
          
           <!-- <div class="col-6 col-md-3">
             <div class="card border-0 shadow-light text-center mb-4 card-test">
               <div class="card-body position-relative">
                 <div class="white-bg">
                      <img src="../assets/images/mobile-hd.png" alt="" />
                      <div class="white-bg-txt">
                        <span class="excerpt">
                          RealMe1(4+64GB)
                          <span>Starting at &#8377; 10,990</span>
                        </span>
                         <div class="text-center mt-3">
                            <div class="edit-icon text-right float-right">
                                <div class="container popup popup-custom">
                                  <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                                  </div></div>
                                  <div class="card-footer" >
                                    <div class="separator clear-left">
                            <img src="../assets/images/amazon-deal.png" alt="" class="w-100" />
                         </div></div></div>
                      </div>
                 </div>
               </div>
             </div>
           </div> -->
           <!-- <div class="col-6 col-md-3">
             <div class="card border-0 shadow-light text-center mb-4 card-test">
               <div class="card-body position-relative">
                 <div class="white-bg">
                      <img src="../assets/images/mobile-hd.png" alt="" />
                      <div class="white-bg-txt">
                        <span class="excerpt">
                          RealMe1(4+64GB)
                          <span>Starting at &#8377; 10,990</span>
                        </span>
                         <div class="text-center mt-3">
                            <div class="edit-icon text-right float-right">
                                <div class="container popup popup-custom">
                                  <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                                  </div></div>
                                  <div class="card-footer" >
                                    <div class="separator clear-left">
                            <img src="../assets/images/amazon-deal.png" alt="" class="w-100" />
                         </div></div></div>
                      </div>
                 </div>
               </div>
             </div>
           </div> -->
           <!-- <div class="col-6 col-md-3">
             <div class="card border-0 shadow-light text-center mb-4 card-test">
               <div class="card-body position-relative">
                 <div class="white-bg">
                      <img src="../assets/images/mobile-hd.png" alt="" />
                      <div class="white-bg-txt">
                        <span class="excerpt">
                          RealMe1(4+64GB)
                          <span>Starting at &#8377; 10,990</span>
                        </span>
                         <div class="text-center mt-3">
                            <div class="edit-icon text-right float-right">
                                <div class="container popup popup-custom">
                                  <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                                  </div></div>
                                  <div class="card-footer" >
                                    <div class="separator clear-left">
                            <img src="../assets/images/amazon-deal.png" alt="" class="w-50" />
                         </div></div></div>
                      </div>
                 </div>
               </div>
             </div>
           </div> -->
           <!-- <div class="col-6 col-md-3">
             <div class="card border-0 shadow-light text-center mb-4 card-test">
               <div class="card-body position-relative">
                 <div class="white-bg">
                      <img src="../assets/images/mobile-hd.png" alt="" />
                      <div class="white-bg-txt">
                        <span class="excerpt">
                          RealMe1(4+64GB)
                          <span>Starting at &#8377; 10,990</span>
                        </span>
                         <div class="text-center mt-3">
                            <div class="edit-icon text-right float-right">
                                <div class="container popup popup-custom">
                                  <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                                  </div></div>
                                  <div class="card-footer" >
                                    <div class="separator clear-left">
                            <img src="../assets/images/amazon-deal.png" alt="" class="w-50" />
                         </div></div></div>
                      </div>
                 </div>
               </div>
             </div>
           </div> -->
           <!-- <div class="col-6 col-md-3">
             <div class="card border-0 shadow-light text-center mb-4 card-test">
               <div class="card-body position-relative">
                 <div class="white-bg">
                      <img src="../assets/images/mobile-hd.png" alt="" />
                      <div class="white-bg-txt">
                        <span class="excerpt">
                          RealMe1(4+64GB)
                          <span>Starting at &#8377; 10,990</span>
                        </span>
                         <div class="text-center mt-3">
                            <div class="edit-icon text-right float-right">
                                <div class="container popup popup-custom">
                                  <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                                  </div></div>
                                  <div class="card-footer" >
                                    <div class="separator clear-left">
                            <img src="../assets/images/amazon-deal.png" alt="" class="w-50" />
                         </div></div></div>
                      </div>
                 </div>
               </div>
             </div>
           </div>   -->
               </div>
            </div>
        </div>
       <div class="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
         <div class="row">
              <!-- <div class="col-6 col-md-3">
             <div class="card border-0 shadow-light text-center mb-4 card-test">
               <div class="card-body position-relative">
                 <div class="white-bg">
                      <img src="../assets/images/mobile-hd.png" alt="" />
                      <div class="white-bg-txt">
                        <span class="excerpt">
                          RealMe1(4+64GB)
                          <span>Starting at &#8377; 10,990</span>
                        </span>
                         <div class="text-center mt-3">
                            <div class="edit-icon text-right float-right">
                                <div class="container popup popup-custom">
                                  <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                                  </div></div>
                                  <div class="card-footer" >
                                    <div class="separator clear-left">
                            <img src="../assets/images/amazon-deal.png" alt="" class="w-50" />
                         </div></div></div>
                      </div>
                 </div>
               </div>
             </div>
           </div> -->
           <!-- <div class="col-6 col-md-3">
             <div class="card border-0 shadow-light text-center mb-4 card-test">
               <div class="card-body position-relative">
                 <div class="white-bg">
                      <img src="../assets/images/mobile-hd.png" alt="" />
                      <div class="white-bg-txt">
                        <span class="excerpt">
                          RealMe1(4+64GB)
                          <span>Starting at &#8377; 10,990</span>
                        </span>
                         <div class="text-center mt-3">
                            <div class="edit-icon text-right float-right">
                                <div class="container popup popup-custom">
                                  <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                                  </div></div>
                                  <div class="card-footer" >
                                    <div class="separator clear-left">
                            <img src="../assets/images/amazon-deal.png" alt="" class="w-50" />
                         </div></div></div>
                      </div>
                 </div>
               </div>
             </div>
           </div> -->
           <!-- <div class="col-6 col-md-3">
             <div class="card border-0 shadow-light text-center mb-4 card-test">
               <div class="card-body position-relative">
                 <div class="white-bg">
                      <img src="../assets/images/mobile-hd.png" alt="" />
                      <div class="white-bg-txt">
                        <span class="excerpt">
                          RealMe1(4+64GB)
                          <span>Starting at &#8377; 10,990</span>
                        </span>
                         <div class="text-center mt-3">
                            <div class="edit-icon text-right float-right">
                                <div class="container popup popup-custom">
                                  <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                                  </div></div>
                                  <div class="card-footer" >
                                    <div class="separator clear-left">
                            <img src="../assets/images/amazon-deal.png" alt="" class="w-50" />
                         </div></div></div>
                      </div>
                 </div>
               </div>
             </div>
           </div> -->
           <!-- <div class="col-6 col-md-3">
             <div class="card border-0 shadow-light text-center mb-4 card-test">
               <div class="card-body position-relative">
                 <div class="white-bg">
                      <img src="../assets/images/mobile-hd.png" alt="" />
                      <div class="white-bg-txt">
                        <span class="excerpt">
                          RealMe1(4+64GB)
                          <span>Starting at &#8377; 10,990</span>
                        </span>
                         <div class="text-center mt-3">
                            <div class="edit-icon text-right float-right">
                                <div class="container popup popup-custom">
                                  <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                                  </div></div>
                                  <div class="card-footer" >
                                    <div class="separator clear-left">
                            <img src="../assets/images/amazon-deal.png" alt="" class="w-50" />
                         </div></div></div>
                      </div>
                 </div>
               </div>
             </div>
           </div> -->
           <!-- <div class="col-6 col-md-3">
             <div class="card border-0 shadow-light text-center mb-4 card-test">
               <div class="card-body position-relative">
                 <div class="white-bg">
                      <img src="../assets/images/mobile-hd.png" alt="" />
                      <div class="white-bg-txt">
                        <span class="excerpt">
                          RealMe1(4+64GB)
                          <span>Starting at &#8377; 10,990</span>
                        </span>
                         <div class="text-center mt-3">
                            <div class="edit-icon text-right float-right">
                                <div class="container popup popup-custom">
                                  <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                                  </div></div>
                                  <div class="card-footer" >
                                    <div class="separator clear-left">
                            <img src="../assets/images/amazon-deal.png" alt="" class="w-50" />
                         </div></div></div>
                      </div>
                 </div>
               </div>
             </div>
           </div> -->
           <!-- <div class="col-6 col-md-3">
             <div class="card border-0 shadow-light text-center mb-4 card-test">
               <div class="card-body position-relative">
                 <div class="white-bg">
                      <img src="../assets/images/mobile-hd.png" alt="" />
                      <div class="white-bg-txt">
                        <span class="excerpt">
                          RealMe1(4+64GB)
                          <span>Starting at &#8377; 10,990</span>
                        </span>
                         <div class="text-center mt-3">
                            <div class="edit-icon text-right float-right">
                                <div class="container popup">
                                  <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a>
                                  </div></div>
                                  <div class="card-footer" >
                                    <div class="separator clear-left">
                            <img src="../assets/images/amazon-deal.png" alt="" class="w-50" />
                         </div></div></div>
                      </div>
                 </div>
               </div>
             </div>
           </div> -->
         </div>
       </div>
   </div>
</section> 

<!-- offers end -->
<!-- sticky footer tabs -->
<section id="foot">
  <div class="footer-tabs border-top text-center">
    <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" routerLink="/"><i class="fas fa-home"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/offers/"><i class="fas fa-handshake"></i>
        </a>
      </li>
      <li class="nav-item centerlarge">
      
        <a class="nav-link bg-default"  *ngIf="user_id" routerLink="/favourite"
          ><i class="fa fa-star"></i>
        </a>
        <a class="nav-link bg-default" *ngIf="!user_id" routerLink="/login"
          ><i class="fa fa-star"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/"><i class="fas fa-bell"></i>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/store"><i class="fas fa-globe"></i>
        </a>
      </li>
      
    </ul>
  </div>
</section>


<!-- scroll to top button -->
<button type="button" class="btn btn-default scrollup position-fixed btn-44" style="background-color: aqua;"> <i class="fas fa-chevron-up"></i> </button>
<!-- scroll to top button ends--> 