import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
 allData : string[] = [];
// newData:string
  constructor() { }

  setTokenInitialData(token:string , email:string , name:string,id:string){
       window.sessionStorage.setItem('token' ,token);
       window.sessionStorage.setItem('email' ,email);
       window.sessionStorage.setItem('name' ,name);
       window.sessionStorage.setItem('id' ,id);
  }


  destroyToken(){
    window.sessionStorage.removeItem('token');
    window.sessionStorage.removeItem('name');
    window.sessionStorage.removeItem('email');
    window.sessionStorage.removeItem('id');
  }
  

  getAllData(){
    const allData=[];
   allData.push(window.sessionStorage.getItem('name'));
   
   return allData;
  }
  getAllToken(){
    const allData=[];
   allData.push(window.sessionStorage.getItem('token'));
   
   return allData;
  }

  // getId(){
  //   const allData=[];
  //  allData.push(window.sessionStorage.getItem('id'));
   
  //  return allData;
  // }



  
}


