<section class="category-page">
  <header class="topheader">
      <nav class="navbar">
          
        <span class="topbar-custom" onclick="openNav()">&#9776; </span>
            <div class="logo"> <a href="#"><img src="../assets/logo-home.png" style="margin-left: -10%;"></a></div>          
            <div class="header-search">
              <div class="search-main dropdown"> 
                  <!-- <a href="javaScript:void(0)" class="toggle"><i class="fas fa-search"></i></a> -->
                <div class="dropdown-menu top-search">
                   <!-- <input type="text" id="somenth" class="form-control" placeholder="Search.." /> -->
                  <!-- <button type="submit" class="btn btn-submit"><i class="fas fa-search"></i></button> -->
                </div>
              </div>
              <!-- <a href="#" class=""> <span class="avatar avatar-30"><img src="../assets/user5.jpg" alt=""></span> </a>--> </div>  
      </nav>
    </header>
    <!-- Fixed navbar ends -->
  
  <!-- side navbar start -->
    <div id="mySidenav" class="sidenav">
      <div class="side-menu-wrap">        
        <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
        <div class="row no-gutters mb-4">
          <div class="col-auto align-self-center ml-3">
            <figure class="avatar avatar-50"> <img src="../assets/images/user5.png" alt=""> </figure>
          </div>
          <div class="col pl-3 align-self-center">
            <p class="my-0" style="color: black;"*ngIf="!user_id" >Hii There</p>
            <p class="my-0" style="color: black;"*ngIf="user_id" >{{currentUser}}</p>
          </div>
          <div class="col-auto align-self-center"> <a (click)='logout()'*ngIf="user_id" class="btn btn-link text-white p-2"><i class="fas fa-power-off"></i></a> </div>
        </div>
        <a routerLink="/" class="active"><i class="fas fa-home"></i>Home</a>
        <a href="#"><i class="fas fa-star"></i>Rate Us</a> 
        <!-- <a href="about-us.html"><i class="fas fa-address-card"></i>About Us</a>  -->
        <!-- <a href="contact.html"><i class="fas fa-address-book"></i>Contact Us</a>  -->
        <a href="#"><i class="fas fa-share-alt"></i>Share</a> 
        <a routerLink="/register"><i class="fas fa-sign-in-alt"></i>Sign up</a> 
        <a routerLink="/login" *ngIf="!user_id"><i class="fas fa-user"></i>Log In</a> 
        <a routerLink="/login" *ngIf="!user_id"><i class="fas fa-user-circle"></i>Profile</a> 
        <a routerLink="/profile" *ngIf="user_id"><i class="fas fa-user-circle"></i>Profile</a> 
      </div>
  
  </div>
  
      <!-- side navbar end -->
      
      <!-- Page Content -->
      <form [formGroup] = "searchStore" (ngSubmit)= "onSubmit()">
        <div>
          <div class="mb-3 mt-5 position-relative multi-search">
              <div class="container">
                <h2 class="page-subtitle category" style="text-align: center;">All Stores</h2>
                 <div class="row ">
                   
                  <div class="col-9 col-md-9">
                    <input type="text" class="shadow-light form-control" placeholder="Search..."  formControlName="search">
                    <button type="submit" class="btn btn-submit"><i class="fas fa-search"></i></button>
                  </div>
                   <div class="col-8 col-md-8 dropdown-custom-maindiv">
                   </div>
                 </div>
              </div>
            </div>
        </div>  
      </form>


      </section> 
  
  
  <!-- Page Content -->
  <section class="category-page">
  
      <div class="hot-topics trending-website">
        <div class="container" style="margin-top:-45px;">
  
          <div class="row">
            <div class="col-4 col-md-3 img-resp"  *ngFor="let websitesItem of websiteLists; let i=index">
              <div class="card border-0 shadow-light text-center mb-4">
                <div class="top-right mt-2" data-toggle="modal" data-target="#myModal">
              
                </div>
                <a href="{{websitesItem.store_url}}" target="_blank">
                <div class="card-body position-relative">
                  <div class="h-100px position-relative overflow-hidden">
                    <div class="background background-h-100" style="background-image: 
                                                url({{websitesItem.icon}});"> 
                                                 <div class="edit-icon text-right float-right">
                                                  <div class="container popup">
                                                    <!-- <a href="javascript:void(0);" role="button" class="popup" data-toggle="popover" title="" data-content="<a href='' title='test add link'>Edit</a><br><br><a href='' title='test add link'>Delete</a>"><span>&#8942;</span></a> -->
                                                    </div></div></div>
                  </div>
                </div>
               </a>
              </div>
            </div>
         
      
          </div>
        </div>
      </div>
    </section>
    
    <!-- Page Content End --> 
    <section id="foot">
      <div class="footer-tabs border-top text-center">
        <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" routerLink="/"><i class="fas fa-home"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/offers/"><i class="fas fa-handshake"></i>
            </a>
          </li>
          <li class="nav-item centerlarge">
          
            <a class="nav-link bg-default"  *ngIf="user_id" routerLink="/favourite"
              ><i class="fa fa-star"></i>
            </a>
            <a class="nav-link bg-default" *ngIf="!user_id" routerLink="/login"
              ><i class="fa fa-star"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/"><i class="fas fa-bell"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/allstore"><i class="fas fa-globe"></i>
            </a>
          </li>
          
        </ul>
      </div>
    </section>
    
    <!-- sticky footer tabs ends --> 
    
    <!-- scroll to top button -->
    <button type="button" class="btn btn-default scrollup position-fixed btn-44" style="background-color: aqua;"> <i class="fas fa-chevron-up"></i> </button>
    <!-- scroll to top button ends--> 